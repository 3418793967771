/* Masters.css */
.masters-container {
  height: calc(100vh - 4rem);
  padding: 1rem 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #f0f4f8;
  overflow: hidden; /* Prevent container scrolling */
  max-width: 100%;
  margin: 0 auto;
  scroll-behavior: smooth;
}

.masters-header {
  margin-bottom: 0.5rem;
  padding: 0;
}

.masters-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
}

.add-platform-btn {
  display: none;
}

.platform-cards {
  display: flex;
  gap: 0.5rem;
  margin: 0.25rem 0;
  padding: 0.15rem 0;
  height: auto;
  min-height: 2.5rem;
  overflow-x: auto;
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

.platform-cards.hidden {
  display: none;
}

.platform-cards::-webkit-scrollbar {
  height: 4px;
}

.platform-cards::-webkit-scrollbar-track {
  background: transparent;
}

.platform-cards::-webkit-scrollbar-thumb {
  background-color: rgba(79, 70, 229, 0.2);
  border-radius: 2px;
}

.platform-card {
  min-width: 120px;
  max-width: 150px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  background: white;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #e2e8f0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  height: 2.5rem;
}

.platform-card:hover:not(.active) {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(79, 70, 229, 0.1);
  border-color: #4f46e5;
}

.platform-card.active {
  background: linear-gradient(145deg, #4f46e5, #4338ca);
  border-color: transparent;
  color: white;
}

.platform-card.active .platform-icon {
  background: rgba(255, 255, 255, 0.2);
}

.platform-card.active h3 {
  color: white;
}

.platform-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: rgba(79, 70, 229, 0.1);
  border-radius: 6px;
}

.platform-card .platform-info {
  flex: 1;
  min-width: 0;
}

.platform-card .platform-info h3 {
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.platform-info p {
  color: #64748b;
  font-size: 0.8rem;
  margin-bottom: 0.15rem;
}

.last-sync {
  font-size: 0.7rem;
  color: #94a3b8;
}

.products-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 0.25rem;
  height: calc(100vh - 8.5rem);
}

.products-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  background: white;
  border-bottom: 1px solid #e2e8f0;
}

.masters-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: 600px;
}

.column-select-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.column-label {
  font-size: 0.875rem;
  color: #4b5563;
  font-weight: 500;
  white-space: nowrap;
}

.masters-actions {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
  padding-right: 1rem;
}

.action-buttons-group {
  display: flex;
  gap: 8px;
  align-items: center;
}

.clear-btn {
  background: linear-gradient(145deg, #fee2e2, #fecaca);
  color: #dc2626;
  border: 1px solid #fca5a5;
}

.clear-btn:hover {
  background: linear-gradient(145deg, #fecaca, #fee2e2);
  transform: translateY(-1px);
}

.column-select {
  min-width: 160px;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  color: #1e293b;
  font-size: 0.875rem;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;
}

.column-select:hover {
  border-color: #cbd5e1;
}

.column-select:focus {
  border-color: #4f46e5;
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.1);
}

.search-input-container {
  position: relative;
  flex: 1;
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #94a3b8;
}

.search-input {
  width: 100%;
  padding: 8px 12px 8px 40px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.875rem;
  color: #1e293b;
  background: #ffffff;
  outline: none;
  transition: all 0.2s;
}

.search-input::placeholder {
  color: #94a3b8;
  opacity: 0.8;
}

.search-input:hover {
  border-color: #cbd5e1;
}

.search-input:focus {
  border-color: #4f46e5;
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.1);
  color: #1e293b;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .search-container {
    flex-direction: column;
    gap: 8px;
  }

  .column-select {
    width: 100%;
  }
}

.add-product-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.2);
  position: relative;
  overflow: hidden;
}

.add-product-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 12px -1px rgba(79, 70, 229, 0.3);
}

.products-table-container {
  overflow-x: auto;
}

.products-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.products-table th {
  background-color: #f8fafc;
  padding: 8px 12px;
  text-align: left;
  font-weight: 600;
  font-size: 0.875rem;
  color: #475569;
  border-bottom: 2px solid #e2e8f0;
  white-space: nowrap;
}

.products-table th.serial-number,
.products-table td.serial-number {
  width: 60px;
  text-align: center;
  color: #64748b;
  font-weight: 500;
}

.products-table td {
  padding: 8px 12px;
  border-bottom: 1px solid #e2e8f0;
  color: #1f2937;
  font-size: 0.875rem;
}

.status-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 999px;
  font-size: 0.75rem;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.status-in-stock {
  background: #dcfce7;
  color: #16a34a;
}

.status-low-stock {
  background: #fef3c7;
  color: #d97706;
}

.status-out-of-stock {
  background: #fee2e2;
  color: #dc2626;
}

.masters-action-buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 4px;
  min-width: 120px;
}

.masters-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0;
  margin: 0;
}

.masters-btn-view {
  background-color: #eff6ff;
  color: #3b82f6;
  border: 1px solid #bfdbfe;
}

.masters-btn-edit {
  background-color: #ecfdf5;
  color: #10b981;
  border: 1px solid #a7f3d0;
}

.masters-btn-delete {
  background-color: #fef2f2;
  color: #ef4444;
  border: 1px solid #fecaca;
}

.masters-btn svg {
  width: 16px;
  height: 16px;
  display: block;
}

.masters-tooltip {
  position: relative;
}

.masters-tooltip:before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 10px;
  background-color: #1f2937;
  color: white;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 10;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.masters-tooltip:hover:before {
  opacity: 1;
  visibility: visible;
  bottom: 130%;
}

.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  background: linear-gradient(
    90deg,
    #f0f4f8 0%,
    #e2e8f0 50%,
    #f0f4f8 100%
  );
  background-size: 1000px 100%;
  animation: shimmer 2s infinite;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid transparent;
  border-top: 3px solid #4f46e5;
  border-right: 3px solid #4f46e5;
  box-shadow: 0 0 15px rgba(79, 70, 229, 0.2);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.error-state {
  text-align: center;
  padding: 3rem;
  color: #dc2626;
}

@media (max-width: 768px) {
  .masters-container {
    padding: 0.5rem;
  }

  .filters-card {
    padding: 0.75rem;
  }

  .products-table {
    font-size: 0.875rem;
  }

  .products-table th,
  .products-table td {
    padding: 0.5rem;
  }

  .platform-cards {
    gap: 0.75rem;
    padding: 0.25rem;
  }

  .platform-card {
    min-width: 140px;
    padding: 0.6rem 0.8rem;
  }

  .platform-icon {
    width: 32px;
    height: 32px;
  }

  .platform-info h3 {
    font-size: 0.8rem;
  }
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(15, 23, 42, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.edit-modal, .view-modal {
  width: 90%;
  max-width: 700px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid #e2e8f0;
  background: #f8fafc;
}

.modal-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.modal-body {
  padding: 1.5rem;
  overflow-y: auto;
  max-height: calc(80vh - 130px);
}

.modal-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  border-top: 1px solid #e2e8f0;
  background: #f8fafc;
}

/* Enhanced Form Controls for Edit Modal */
.edit-form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.25rem;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #334155;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 0.875rem;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  background-color: #fff;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.required-mark {
  color: #ef4444;
  margin-left: 0.25rem;
}

.form-control.is-invalid {
  border-color: #ef4444;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ef4444' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ef4444' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 16px;
}

.error-message {
  color: #ef4444;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.help-text {
  display: block;
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: #64748b;
}

/* View Modal Specific Styling */
.view-data {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.data-item {
  margin-bottom: 1rem;
}

.data-item .label {
  font-size: 0.75rem;
  color: #64748b;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.data-item .value {
  font-size: 0.9rem;
  color: #0f172a;
  padding: 0.625rem;
  background: #f8fafc;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  min-height: 2.5rem;
  word-break: break-word;
}

/* Modal Buttons */
.modal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.625rem 1.25rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
  height: 40px;
  min-width: 100px;
}

.modal-btn-primary {
  background: linear-gradient(145deg, #3b82f6, #2563eb);
  color: white;
  border: none;
}

.modal-btn-primary:hover {
  background: linear-gradient(145deg, #2563eb, #1d4ed8);
  transform: translateY(-1px);
}

.modal-btn-secondary {
  background: white;
  color: #475569;
  border: 1px solid #cbd5e1;
}

.modal-btn-secondary:hover {
  background: #f1f5f9;
  border-color: #94a3b8;
}

.modal-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.close-btn {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #64748b;
  transition: all 0.2s;
}

.close-btn:hover {
  background: #f1f5f9;
  color: #0f172a;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .edit-form, .view-data {
    grid-template-columns: 1fr;
  }
  
  .modal-content {
    width: 95%;
  }
  
  .modal-header h2 {
    font-size: 1.1rem;
  }
}

/* Status badges */
[data-type="status"] {
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 0.8rem;
  font-weight: 500;
}

.status-active {
  background: rgba(16, 185, 129, 0.1);
  color: #059669;
}

.status-inactive {
  background: rgba(239, 68, 68, 0.1);
  color: #dc2626;
}

.status-pending {
  background: rgba(245, 158, 11, 0.1);
  color: #d97706;
}

/* Price values */
[data-type="price"] {
  font-family: 'SF Mono', 'Roboto Mono', monospace;
  color: #059669;
  font-weight: 600;
}

/* Date values */
[data-type="date"] {
  color: #6366f1;
  font-size: 0.85rem;
}

.modal-footer {
  padding: 12px 20px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid #e2e8f0;
  background: #f8fafc;
  border-radius: 0 0 20px 20px;
}

.modal-btn-primary {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  border-radius: 8px;
  background: #4f46e5;
  color: white;
  font-weight: 500;
  font-size: 0.875rem;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.modal-btn-primary:hover {
  background: #4338ca;
}

.close-btn {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.2s;
}

.close-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: rotate(90deg);
}

/* Scrollbar */
.modal-body::-webkit-scrollbar {
  width: 4px;
}

.modal-body::-webkit-scrollbar-track {
  background: transparent;
}

.modal-body::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 2px;
}

/* Animation */
.view-modal {
  animation: modalEnter 0.2s ease-out;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #1e293b;
  font-size: 0.9rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 6px;
  font-size: 0.9rem;
  background: linear-gradient(145deg, #f8fafc, #ffffff);
  box-shadow: inset 2px 2px 5px #d1d9e6,
              inset -2px -2px 5px #ffffff;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.1);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.modal-actions button {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.modal-actions button[type="submit"] {
  background: #4f46e5;
  color: white;
  border: none;
}

.modal-actions button[type="button"] {
  background: #f1f5f9;
  border: none;
}

/* Enhanced Modal Styles */
.modal-overlay {
  animation: fadeIn 0.2s ease;
}

.modal-content {
  animation: slideIn 0.3s ease;
}

.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-row .form-group {
  flex: 1;
}

.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  min-height: 100px;
  resize: vertical;
}

.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  background: white;
}

/* Enhanced button animations */
.action-btn {
  position: relative;
  overflow: hidden;
}

.action-btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.3s, height 0.3s;
}

.action-btn:hover::after {
  width: 150%;
  height: 150%;
}

.action-btn.view:hover {
  background: #ecfdf5;
  transform: translateY(-2px);
}

.action-btn.delete:hover {
  background: #fee2e2;
  transform: translateY(-2px);
}

/* Modal transitions */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { 
    opacity: 0;
    transform: translateY(-20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

/* Form field animations */
.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Update table wrapper styles to match PO table */
.table-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 0.5rem;
  position: relative;
}


/* Optimize scrollbar styling */
.table-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.table-scroll::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.table-scroll::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}

/* Update table styles */
.products-table {
  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
  border-spacing: 0;
}

/* Update header styles */
.products-table thead {
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
}

.products-table th {
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: left;
  color: #4b5563;
  background: #f8fafc;
  border-bottom: 1px solid #e5e7eb;
  position: relative;
  padding-right: 1.5rem;
}

.products-table th::after {
  content: '';
  position: absolute;
  right: 0;
  top: 25%;
  height: 50%;
  width: 4px;
  background: #e2e8f0;
  cursor: col-resize;
  opacity: 0;
  transition: opacity 0.2s;
}

.products-table th:hover::after {
  opacity: 1;
}

/* Update cell styles */
.products-table td {
  padding: 0.75rem;
  font-size: 0.875rem;
  color: #4b5563;
  border-bottom: 1px solid #e5e7eb;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Add hover effect */
.products-table tbody tr:hover {
  background-color: #f9fafb;
}

/* Update filters card */
.filters-card {
  padding: 0.5rem;
  background: white;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  height: auto;
  min-height: 3.5rem;
}

/* Cool Animations */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.loading-state {
  background: linear-gradient(
    90deg,
    #f0f4f8 0%,
    #e2e8f0 50%,
    #f0f4f8 100%
  );
  background-size: 1000px 100%;
  animation: shimmer 2s infinite;
}

/* New feature: Row highlight on hover */
.products-table tr {
  transition: all 0.2s ease;
}

.products-table tbody tr:hover {
  background: linear-gradient(90deg, #f8fafc 0%, #eef2ff 100%);
  transform: scale(1.002);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* New feature: Selected row highlight */
.products-table tr.selected {
  background: linear-gradient(90deg, #eef2ff 0%, #e0e7ff 100%);
}

/* New feature: Column sorting indicators */
.products-table th.sortable {
  cursor: pointer;
}

.products-table th.sortable::before {
  content: '↕️';
  position: absolute;
  right: 0.5rem;
  opacity: 0.3;
}

.products-table th.sorted-asc::before {
  content: '↑';
  opacity: 1;
}

.products-table th.sorted-desc::before {
  content: '↓';
  opacity: 1;
}

/* New feature: Quick actions toolbar */
.quick-actions {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  background: linear-gradient(90deg, #f8fafc 0%, #f1f5f9 100%);
  border-bottom: 1px solid #e2e8f0;
}

.quick-action-btn {
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
  background: white;
  border: 1px solid #e2e8f0;
  color: #4b5563;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.quick-action-btn:hover {
  background: #f8fafc;
  border-color: #cbd5e1;
  transform: translateY(-1px);
}

/* New feature: Batch selection indicator */
.selection-indicator {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: #4f46e5;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 999px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 50;
}

.selection-indicator.visible {
  opacity: 1;
}

/* New feature: Column visibility toggle dropdown */
.column-visibility {
  position: relative;
  display: inline-block;
}

.column-toggle-btn {
  padding: 0.5rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  cursor: pointer;
}

.column-list {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 40;
}

.column-list label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

/* New feature: Inline editing styles */
.editable-cell {
  position: relative;
}

.editable-cell input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0.75rem;
  border: 2px solid #4f46e5;
  border-radius: 4px;
  background: white;
  z-index: 20;
}

/* New feature: Row expansion animation */
.expandable-row {
  transition: all 0.3s ease;
}

.expandable-row.expanded {
  background: #f8fafc;
}

.row-details {
  padding: 1rem;
  background: white;
  border-top: 1px solid #e2e8f0;
  animation: slideDown 0.3s ease;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Enhanced table styling */
.products-table {
  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
}

.products-table th {
  background: linear-gradient(145deg, #f8fafc, #f1f5f9);
  font-weight: 600;
  color: #1e293b;
  transition: background-color 0.2s;
}

.products-table tr:hover {
  background: linear-gradient(90deg, #f8fafc 0%, #eef2ff 100%);
  transition: all 0.2s ease;
}

/* Enhanced modal styling */
.modal-content {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 2rem;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.modal-content h2 {
  color: #1e293b;
  font-size: 1.5rem;
  font-weight: 600;
}

.form-group label {
  color: #1e293b;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  color: #1e293b;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
  outline: none;
}

/* View mode styling */
.view-mode {
  padding: 20px;
}

.form-row {
  margin-bottom: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #475569;
}

.form-input {
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #1e293b;
  width: 100%;
  transition: all 0.2s;
}

.form-input:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.form-input:disabled {
  background-color: #f8fafc;
  cursor: not-allowed;
}

/* Keep existing modal footer styles */
.modal-footer {
  padding: 16px 24px;
  background: white;
  border-top: 1px solid #e2e8f0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

/* Enhanced action buttons */
.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.action-btn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.2s;
}

.action-btn.edit {
  background: linear-gradient(145deg, #eef2ff, #e0e7ff);
  color: #4f46e5;
}

.action-btn.view {
  background: linear-gradient(145deg, #ecfdf5, #d1fae5);
  color: #059669;
}

.action-btn.delete {
  background: linear-gradient(145deg, #fef2f2, #fee2e2);
  color: #dc2626;
}

.action-btn:hover {
  transform: translateY(-2px);
  filter: brightness(0.95);
}

/* Modal actions */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.modal-actions button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s;
}

.modal-actions button[type="submit"] {
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  color: white;
  border: none;
}

.modal-actions button[type="button"] {
  background: #f1f5f9;
  color: #64748b;
  border: none;
}

.modal-actions button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Status badge enhancements */
.status-badge {
  padding: 0.4rem 1rem;
  border-radius: 999px;
  font-size: 0.75rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.status-in-stock {
  background: linear-gradient(145deg, #dcfce7, #bbf7d0);
  color: #16a34a;
}

.status-low-stock {
  background: linear-gradient(145deg, #fef3c7, #fde68a);
  color: #d97706;
}

.status-out-of-stock {
  background: linear-gradient(145deg, #fee2e2, #fecaca);
  color: #dc2626;
}

/* Form row layout */
.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-row .form-group {
  flex: 1;
  margin-bottom: 0;
}

/* Loading state enhancement */
.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  gap: 1rem;
}

.loading-spinner {
  border: 3px solid #e2e8f0;
  border-top: 3px solid #4f46e5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.master-type-cards {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 0.25rem;
  padding: 0;
  height: 50px;
}

.master-type-card {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  gap: 0.75rem;
  background: white;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.master-type-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(79, 70, 229, 0.1);
}

.master-type-card.active {
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  border-color: transparent;
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.2);
}

.master-type-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: rgba(79, 70, 229, 0.1);
  border-radius: 8px;
}

.master-type-card h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.master-type-card.active h3 {
  color: white;
}

/* Style the table header actions */
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.25rem;
  background: white;
  border-bottom: 1px solid #e2e8f0;
}

.header-actions {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.action-btn-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.action-btn-header:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.download-btn {
  background: linear-gradient(145deg, #e0e7ff, #c7d2fe);
  color: #4f46e5;
  border-color: #818cf8;
}

.download-btn:hover {
  background: linear-gradient(145deg, #c7d2fe, #a5b4fc);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.1);
}

.upload-btn {
  background: linear-gradient(145deg, #dcfce7, #bbf7d0);
  color: #059669;
  border-color: #34d399;
}

.upload-btn:hover {
  background: linear-gradient(145deg, #bbf7d0, #86efac);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(5, 150, 105, 0.1);
}

.clear-btn {
  background: linear-gradient(145deg, #fee2e2, #fecaca);
  color: #dc2626;
  border-color: #f87171;
}

.clear-btn:hover {
  background: linear-gradient(145deg, #fecaca, #fca5a5);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(220, 38, 38, 0.1);
}

.table-container {
  flex: 1;
  overflow-y: auto; /* Allow vertical scrolling only in table */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Customize scrollbar for table */
.table-container::-webkit-scrollbar {
  width: 6px;
}

.table-container::-webkit-scrollbar-track {
  background: transparent;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: rgba(203, 213, 225, 0.4);
  border-radius: 3px;
}

/* For Firefox */
.table-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(203, 213, 225, 0.4) transparent;
}

/* Remove any conflicting styles */
.action-btn,
.action-buttons {
  display: none !important;
}

/* Update form styles to match previous layout */
.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #475569;
}

.form-group input,
.form-group select {
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #1e293b;
  width: 100%;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.modal-content form {
  padding: 20px;
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
  margin: 1rem;
  min-height: 300px;
}

.no-data-icon {
  width: 120px;
  height: 120px;
  margin-bottom: 1.5rem;
  color: #94a3b8;
  opacity: 0.8;
}

.no-data h3 {
  color: #1e293b;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.no-data p {
  color: #64748b;
  font-size: 0.875rem;
  max-width: 300px;
  line-height: 1.5;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.no-data-icon {
  animation: float 3s ease-in-out infinite;
}

/* Enhanced Edit Modal Styles */
.edit-modal {
  max-width: 800px;
  width: 95%;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 20px 40px -12px rgba(0, 0, 0, 0.15);
}

.edit-modal .modal-body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 20px;
  max-height: 70vh;
  overflow-y: auto;
}

.edit-modal .detail-item {
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: #f8fafc;
  transition: all 0.2s;
}

.edit-modal .detail-item:hover {
  border-color: #cbd5e1;
}

.edit-modal .form-group {
  margin: 0;
}

.edit-modal .form-group label {
  display: block;
  font-size: 0.75rem;
  font-weight: 600;
  color: #64748b;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.edit-modal .form-group input,
.edit-modal .form-group select,
.edit-modal .form-group textarea {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  background: white;
  color: #1e293b;
  transition: all 0.2s;
}

.edit-modal .form-group input:focus,
.edit-modal .form-group select:focus,
.edit-modal .form-group textarea:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.edit-modal .form-group input:disabled {
  background-color: #f8fafc;
  cursor: not-allowed;
  color: #64748b;
}

/* Special field styles */
.edit-modal .form-group input[type="number"] {
  font-family: 'SF Mono', 'Roboto Mono', monospace;
}

.edit-modal .form-group select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

/* Status field specific styles */
.edit-modal .form-group select[data-type="status"] {
  font-weight: 500;
}

.edit-modal .form-group select[data-type="status"] option {
  font-weight: 500;
}

/* Price field specific styles */
.edit-modal .form-group input[data-type="price"] {
  font-family: 'SF Mono', 'Roboto Mono', monospace;
  color: #059669;
}

/* Date field specific styles */
.edit-modal .form-group input[type="date"] {
  color: #6366f1;
}

/* Animation for edit modal */
.edit-modal {
  animation: modalEnter 0.2s ease-out;
}

@keyframes modalEnter {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .edit-modal .modal-body {
    grid-template-columns: 1fr;
  }
}

/* Add Record Button Style */
.action-btn-header.add-btn {
  background: linear-gradient(145deg, #4f46e5, #4338ca);
  color: white;
  border-color: #4338ca;
}

.action-btn-header.add-btn:hover {
  background: linear-gradient(145deg, #4338ca, #3730a3);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.1);
}

/* Modal Alert Styles */
.modal-alert-overlay {
  position: fixed; /* Change from absolute to fixed */
  inset: 0;
  background: rgba(15, 23, 42, 0.65);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100; /* Increase z-index to be higher than modal */
}

.modal-alert {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
  z-index: 1101; /* Make sure the alert itself has higher z-index */
}

.modal-alert h3 {
  margin: 0 0 1rem 0;
  color: #0f172a;
  font-size: 1.125rem;
  font-weight: 600;
}

.changes-list {
  background: #f8fafc;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.change-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e2e8f0;
}

.change-item:last-child {
  border-bottom: none;
}

.field-name {
  font-weight: 500;
  color: #64748b;
}

.change-values {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.old-value {
  color: #ef4444;
  text-decoration: line-through;
}

.arrow {
  color: #64748b;
}

.new-value {
  color: #22c55e;
  font-weight: 500;
}

.alert-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}

.non-editable .detail-input {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
}

.readonly-badge {
  font-size: 0.7em;
  background-color: #e0e0e0;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  color: #666;
}

.detail-label {
  display: flex;
  align-items: center;
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(15, 23, 42, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure this is lower than alert overlay */
}

.modal-content {
  background: white;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.edit-modal, .view-modal {
  width: 90%;
  max-width: 700px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid #e2e8f0;
  background: #f8fafc;
}

.modal-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.modal-body {
  padding: 1.5rem;
  overflow-y: auto;
  max-height: calc(80vh - 130px);
}

.modal-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  border-top: 1px solid #e2e8f0;
  background: #f8fafc;
}

/* Enhanced Form Controls for Edit Modal */
.edit-form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.25rem;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #334155;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 0.875rem;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  background-color: #fff;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.required-mark {
  color: #ef4444;
  margin-left: 0.25rem;
}

.form-control.is-invalid {
  border-color: #ef4444;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ef4444' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ef4444' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 16px;
}

.error-message {
  color: #ef4444;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.help-text {
  display: block;
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: #64748b;
}

/* View Modal Specific Styling */
.view-data {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.data-item {
  margin-bottom: 1rem;
}

.data-item .label {
  font-size: 0.75rem;
  color: #64748b;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.data-item .value {
  font-size: 0.9rem;
  color: #0f172a;
  padding: 0.625rem;
  background: #f8fafc;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  min-height: 2.5rem;
  word-break: break-word;
}

/* Modal Buttons */
.modal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.625rem 1.25rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
  height: 40px;
  min-width: 100px;
}

.modal-btn-primary {
  background: linear-gradient(145deg, #3b82f6, #2563eb);
  color: white;
  border: none;
}

.modal-btn-primary:hover {
  background: linear-gradient(145deg, #2563eb, #1d4ed8);
  transform: translateY(-1px);
}

.modal-btn-secondary {
  background: white;
  color: #475569;
  border: 1px solid #cbd5e1;
}

.modal-btn-secondary:hover {
  background: #f1f5f9;
  border-color: #94a3b8;
}

.modal-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.close-btn {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #64748b;
  transition: all 0.2s;
}

.close-btn:hover {
  background: #f1f5f9;
  color: #0f172a;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .edit-form, .view-data {
    grid-template-columns: 1fr;
  }
  
  .modal-content {
    width: 95%;
  }
  
  .modal-header h2 {
    font-size: 1.1rem;
  }
}

/* Status badges */
[data-type="status"] {
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 0.8rem;
  font-weight: 500;
}

.status-active {
  background: rgba(16, 185, 129, 0.1);
  color: #059669;
}

.status-inactive {
  background: rgba(239, 68, 68, 0.1);
  color: #dc2626;
}

.status-pending {
  background: rgba(245, 158, 11, 0.1);
  color: #d97706;
}

/* Price values */
[data-type="price"] {
  font-family: 'SF Mono', 'Roboto Mono', monospace;
  color: #059669;
  font-weight: 600;
}

/* Date values */
[data-type="date"] {
  color: #6366f1;
  font-size: 0.85rem;
}

.modal-footer {
  padding: 12px 20px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid #e2e8f0;
  background: #f8fafc;
  border-radius: 0 0 20px 20px;
}

.modal-btn-primary {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  border-radius: 8px;
  background: #4f46e5;
  color: white;
  font-weight: 500;
  font-size: 0.875rem;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.modal-btn-primary:hover {
  background: #4338ca;
}

.close-btn {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.2s;
}

.close-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: rotate(90deg);
}

/* Scrollbar */
.modal-body::-webkit-scrollbar {
  width: 4px;
}

.modal-body::-webkit-scrollbar-track {
  background: transparent;
}

.modal-body::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 2px;
}

/* Animation */
.view-modal {
  animation: modalEnter 0.2s ease-out;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #1e293b;
  font-size: 0.9rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 6px;
  font-size: 0.9rem;
  background: linear-gradient(145deg, #f8fafc, #ffffff);
  box-shadow: inset 2px 2px 5px #d1d9e6,
              inset -2px -2px 5px #ffffff;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.1);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.modal-actions button {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.modal-actions button[type="submit"] {
  background: #4f46e5;
  color: white;
  border: none;
}

.modal-actions button[type="button"] {
  background: #f1f5f9;
  border: none;
}

/* Enhanced Modal Styles */
.modal-overlay {
  animation: fadeIn 0.2s ease;
}

.modal-content {
  animation: slideIn 0.3s ease;
}

.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-row .form-group {
  flex: 1;
}

.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  min-height: 100px;
  resize: vertical;
}

.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  background: white;
}

/* Enhanced button animations */
.action-btn {
  position: relative;
  overflow: hidden;
}

.action-btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.3s, height 0.3s;
}

.action-btn:hover::after {
  width: 150%;
  height: 150%;
}

.action-btn.view:hover {
  background: #ecfdf5;
  transform: translateY(-2px);
}

.action-btn.delete:hover {
  background: #fee2e2;
  transform: translateY(-2px);
}

/* Modal transitions */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { 
    opacity: 0;
    transform: translateY(-20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

/* Form field animations */
.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Update table wrapper styles to match PO table */
.table-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 0.5rem;
  position: relative;
}


/* Optimize scrollbar styling */
.table-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.table-scroll::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.table-scroll::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}

/* Update table styles */
.products-table {
  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
  border-spacing: 0;
}

/* Update header styles */
.products-table thead {
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
}

.products-table th {
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: left;
  color: #4b5563;
  background: #f8fafc;
  border-bottom: 1px solid #e5e7eb;
  position: relative;
  padding-right: 1.5rem;
}

.products-table th::after {
  content: '';
  position: absolute;
  right: 0;
  top: 25%;
  height: 50%;
  width: 4px;
  background: #e2e8f0;
  cursor: col-resize;
  opacity: 0;
  transition: opacity 0.2s;
}

.products-table th:hover::after {
  opacity: 1;
}

/* Update cell styles */
.products-table td {
  padding: 0.75rem;
  font-size: 0.875rem;
  color: #4b5563;
  border-bottom: 1px solid #e5e7eb;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Add hover effect */
.products-table tbody tr:hover {
  background-color: #f9fafb;
}

/* Update filters card */
.filters-card {
  padding: 0.5rem;
  background: white;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  height: auto;
  min-height: 3.5rem;
}

/* Cool Animations */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.loading-state {
  background: linear-gradient(
    90deg,
    #f0f4f8 0%,
    #e2e8f0 50%,
    #f0f4f8 100%
  );
  background-size: 1000px 100%;
  animation: shimmer 2s infinite;
}

/* New feature: Row highlight on hover */
.products-table tr {
  transition: all 0.2s ease;
}

.products-table tbody tr:hover {
  background: linear-gradient(90deg, #f8fafc 0%, #eef2ff 100%);
  transform: scale(1.002);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* New feature: Selected row highlight */
.products-table tr.selected {
  background: linear-gradient(90deg, #eef2ff 0%, #e0e7ff 100%);
}

/* New feature: Column sorting indicators */
.products-table th.sortable {
  cursor: pointer;
}

.products-table th.sortable::before {
  content: '↕️';
  position: absolute;
  right: 0.5rem;
  opacity: 0.3;
}

.products-table th.sorted-asc::before {
  content: '↑';
  opacity: 1;
}

.products-table th.sorted-desc::before {
  content: '↓';
  opacity: 1;
}

/* New feature: Quick actions toolbar */
.quick-actions {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  background: linear-gradient(90deg, #f8fafc 0%, #f1f5f9 100%);
  border-bottom: 1px solid #e2e8f0;
}

.quick-action-btn {
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
  background: white;
  border: 1px solid #e2e8f0;
  color: #4b5563;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.quick-action-btn:hover {
  background: #f8fafc;
  border-color: #cbd5e1;
  transform: translateY(-1px);
}

/* New feature: Batch selection indicator */
.selection-indicator {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: #4f46e5;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 999px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 50;
}

.selection-indicator.visible {
  opacity: 1;
}

/* New feature: Column visibility toggle dropdown */
.column-visibility {
  position: relative;
  display: inline-block;
}

.column-toggle-btn {
  padding: 0.5rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  cursor: pointer;
}

.column-list {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 40;
}

.column-list label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

/* New feature: Inline editing styles */
.editable-cell {
  position: relative;
}

.editable-cell input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0.75rem;
  border: 2px solid #4f46e5;
  border-radius: 4px;
  background: white;
  z-index: 20;
}

/* New feature: Row expansion animation */
.expandable-row {
  transition: all 0.3s ease;
}

.expandable-row.expanded {
  background: #f8fafc;
}

.row-details {
  padding: 1rem;
  background: white;
  border-top: 1px solid #e2e8f0;
  animation: slideDown 0.3s ease;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Enhanced table styling */
.products-table {
  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
}

.products-table th {
  background: linear-gradient(145deg, #f8fafc, #f1f5f9);
  font-weight: 600;
  color: #1e293b;
  transition: background-color 0.2s;
}

.products-table tr:hover {
  background: linear-gradient(90deg, #f8fafc 0%, #eef2ff 100%);
  transition: all 0.2s ease;
}

/* Enhanced modal styling */
.modal-content {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 2rem;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.modal-content h2 {
  color: #1e293b;
  font-size: 1.5rem;
  font-weight: 600;
}

.form-group label {
  color: #1e293b;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  color: #1e293b;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
  outline: none;
}

/* View mode styling */
.view-mode {
  padding: 20px;
}

.form-row {
  margin-bottom: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #475569;
}

.form-input {
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #1e293b;
  width: 100%;
  transition: all 0.2s;
}

.form-input:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.form-input:disabled {
  background-color: #f8fafc;
  cursor: not-allowed;
}

/* Keep existing modal footer styles */
.modal-footer {
  padding: 16px 24px;
  background: white;
  border-top: 1px solid #e2e8f0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

/* Enhanced action buttons */
.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.action-btn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.2s;
}

.action-btn.edit {
  background: linear-gradient(145deg, #eef2ff, #e0e7ff);
  color: #4f46e5;
}

.action-btn.view {
  background: linear-gradient(145deg, #ecfdf5, #d1fae5);
  color: #059669;
}

.action-btn.delete {
  background: linear-gradient(145deg, #fef2f2, #fee2e2);
  color: #dc2626;
}

.action-btn:hover {
  transform: translateY(-2px);
  filter: brightness(0.95);
}

/* Modal actions */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.modal-actions button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s;
}

.modal-actions button[type="submit"] {
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  color: white;
  border: none;
}

.modal-actions button[type="button"] {
  background: #f1f5f9;
  color: #64748b;
  border: none;
}

.modal-actions button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Status badge enhancements */
.status-badge {
  padding: 0.4rem 1rem;
  border-radius: 999px;
  font-size: 0.75rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.status-in-stock {
  background: linear-gradient(145deg, #dcfce7, #bbf7d0);
  color: #16a34a;
}

.status-low-stock {
  background: linear-gradient(145deg, #fef3c7, #fde68a);
  color: #d97706;
}

.status-out-of-stock {
  background: linear-gradient(145deg, #fee2e2, #fecaca);
  color: #dc2626;
}

/* Form row layout */
.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-row .form-group {
  flex: 1;
  margin-bottom: 0;
}

/* Loading state enhancement */
.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  gap: 1rem;
}

.loading-spinner {
  border: 3px solid #e2e8f0;
  border-top: 3px solid #4f46e5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.master-type-cards {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 0.25rem;
  padding: 0;
  height: 50px;
}

.master-type-card {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  gap: 0.75rem;
  background: white;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.master-type-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(79, 70, 229, 0.1);
}

.master-type-card.active {
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  border-color: transparent;
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.2);
}

.master-type-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: rgba(79, 70, 229, 0.1);
  border-radius: 8px;
}

.master-type-card h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.master-type-card.active h3 {
  color: white;
}

/* Style the table header actions */
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.25rem;
  background: white;
  border-bottom: 1px solid #e2e8f0;
}

.header-actions {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.action-btn-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.action-btn-header:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.download-btn {
  background: linear-gradient(145deg, #e0e7ff, #c7d2fe);
  color: #4f46e5;
  border-color: #818cf8;
}

.download-btn:hover {
  background: linear-gradient(145deg, #c7d2fe, #a5b4fc);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.1);
}

.upload-btn {
  background: linear-gradient(145deg, #dcfce7, #bbf7d0);
  color: #059669;
  border-color: #34d399;
}

.upload-btn:hover {
  background: linear-gradient(145deg, #bbf7d0, #86efac);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(5, 150, 105, 0.1);
}

.clear-btn {
  background: linear-gradient(145deg, #fee2e2, #fecaca);
  color: #dc2626;
  border-color: #f87171;
}

.clear-btn:hover {
  background: linear-gradient(145deg, #fecaca, #fca5a5);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(220, 38, 38, 0.1);
}

.table-container {
  flex: 1;
  overflow-y: auto; /* Allow vertical scrolling only in table */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Customize scrollbar for table */
.table-container::-webkit-scrollbar {
  width: 6px;
}

.table-container::-webkit-scrollbar-track {
  background: transparent;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: rgba(203, 213, 225, 0.4);
  border-radius: 3px;
}

/* For Firefox */
.table-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(203, 213, 225, 0.4) transparent;
}

/* Remove any conflicting styles */
.action-btn,
.action-buttons {
  display: none !important;
}

/* Update form styles to match previous layout */
.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #475569;
}

.form-group input,
.form-group select {
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #1e293b;
  width: 100%;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.modal-content form {
  padding: 20px;
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
  margin: 1rem;
  min-height: 300px;
}

.no-data-icon {
  width: 120px;
  height: 120px;
  margin-bottom: 1.5rem;
  color: #94a3b8;
  opacity: 0.8;
}

.no-data h3 {
  color: #1e293b;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.no-data p {
  color: #64748b;
  font-size: 0.875rem;
  max-width: 300px;
  line-height: 1.5;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.no-data-icon {
  animation: float 3s ease-in-out infinite;
}

/* Enhanced Edit Modal Styles */
.edit-modal {
  max-width: 800px;
  width: 95%;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 20px 40px -12px rgba(0, 0, 0, 0.15);
}

.edit-modal .modal-body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 20px;
  max-height: 70vh;
  overflow-y: auto;
}

.edit-modal .detail-item {
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: #f8fafc;
  transition: all 0.2s;
}

.edit-modal .detail-item:hover {
  border-color: #cbd5e1;
}

.edit-modal .form-group {
  margin: 0;
}

.edit-modal .form-group label {
  display: block;
  font-size: 0.75rem;
  font-weight: 600;
  color: #64748b;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.edit-modal .form-group input,
.edit-modal .form-group select,
.edit-modal .form-group textarea {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  background: white;
  color: #1e293b;
  transition: all 0.2s;
}

.edit-modal .form-group input:focus,
.edit-modal .form-group select:focus,
.edit-modal .form-group textarea:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.edit-modal .form-group input:disabled {
  background-color: #f8fafc;
  cursor: not-allowed;
  color: #64748b;
}

/* Special field styles */
.edit-modal .form-group input[type="number"] {
  font-family: 'SF Mono', 'Roboto Mono', monospace;
}

.edit-modal .form-group select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

/* Status field specific styles */
.edit-modal .form-group select[data-type="status"] {
  font-weight: 500;
}

.edit-modal .form-group select[data-type="status"] option {
  font-weight: 500;
}

/* Price field specific styles */
.edit-modal .form-group input[data-type="price"] {
  font-family: 'SF Mono', 'Roboto Mono', monospace;
  color: #059669;
}

/* Date field specific styles */
.edit-modal .form-group input[type="date"] {
  color: #6366f1;
}

/* Animation for edit modal */
.edit-modal {
  animation: modalEnter 0.2s ease-out;
}

@keyframes modalEnter {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .edit-modal .modal-body {
    grid-template-columns: 1fr;
  }
}

/* Add Record Button Style */
.action-btn-header.add-btn {
  background: linear-gradient(145deg, #4f46e5, #4338ca);
  color: white;
  border-color: #4338ca;
}

.action-btn-header.add-btn:hover {
  background: linear-gradient(145deg, #4338ca, #3730a3);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.1);
}

/* Modal Alert Styles */
.modal-alert-overlay {
  position: fixed; /* Change from absolute to fixed */
  inset: 0;
  background: rgba(15, 23, 42, 0.65);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100; /* Increase z-index to be higher than modal */
}

.modal-alert {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.modal-alert h3 {
  margin: 0 0 1rem 0;
  color: #0f172a;
  font-size: 1.125rem;
  font-weight: 600;
}

.changes-list {
  background: #f8fafc;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.change-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e2e8f0;
}

.change-item:last-child {
  border-bottom: none;
}

.field-name {
  font-weight: 500;
  color: #64748b;
}

.change-values {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.old-value {
  color: #ef4444;
  text-decoration: line-through;
}

.arrow {
  color: #64748b;
}

.new-value {
  color: #22c55e;
  font-weight: 500;
}

.alert-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}

.non-editable .detail-input {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
}

.readonly-badge {
  font-size: 0.7em;
  background-color: #e0e0e0;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  color: #666;
}

.detail-label {
  display: flex;
  align-items: center;
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(15, 23, 42, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.edit-modal, .view-modal {
  width: 90%;
  max-width: 700px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid #e2e8f0;
  background: #f8fafc;
}

.modal-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.modal-body {
  padding: 1.5rem;
  overflow-y: auto;
  max-height: calc(80vh - 130px);
}

.modal-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  border-top: 1px solid #e2e8f0;
  background: #f8fafc;
}

/* Enhanced Form Controls for Edit Modal */
.edit-form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.25rem;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #334155;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 0.875rem;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  background-color: #fff;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.required-mark {
  color: #ef4444;
  margin-left: 0.25rem;
}

.form-control.is-invalid {
  border-color: #ef4444;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ef4444' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ef4444' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 16px;
}

.error-message {
  color: #ef4444;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.help-text {
  display: block;
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: #64748b;
}

/* View Modal Specific Styling */
.view-data {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.data-item {
  margin-bottom: 1rem;
}

.data-item .label {
  font-size: 0.75rem;
  color: #64748b;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.data-item .value {
  font-size: 0.9rem;
  color: #0f172a;
  padding: 0.625rem;
  background: #f8fafc;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  min-height: 2.5rem;
  word-break: break-word;
}

/* Modal Buttons */
.modal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.625rem 1.25rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
  height: 40px;
  min-width: 100px;
}

.modal-btn-primary {
  background: linear-gradient(145deg, #3b82f6, #2563eb);
  color: white;
  border: none;
}

.modal-btn-primary:hover {
  background: linear-gradient(145deg, #2563eb, #1d4ed8);
  transform: translateY(-1px);
}

.modal-btn-secondary {
  background: white;
  color: #475569;
  border: 1px solid #cbd5e1;
}

.modal-btn-secondary:hover {
  background: #f1f5f9;
  border-color: #94a3b8;
}

.modal-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.close-btn {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #64748b;
  transition: all 0.2s;
}

.close-btn:hover {
  background: #f1f5f9;
  color: #0f172a;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .edit-form, .view-data {
    grid-template-columns: 1fr;
  }
  
  .modal-content {
    width: 95%;
  }
  
  .modal-header h2 {
    font-size: 1.1rem;
  }
}

/* Status badges */
[data-type="status"] {
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 0.8rem;
  font-weight: 500;
}

.status-active {
  background: rgba(16, 185, 129, 0.1);
  color: #059669;
}

.status-inactive {
  background: rgba(239, 68, 68, 0.1);
  color: #dc2626;
}

.status-pending {
  background: rgba(245, 158, 11, 0.1);
  color: #d97706;
}

/* Price values */
[data-type="price"] {
  font-family: 'SF Mono', 'Roboto Mono', monospace;
  color: #059669;
  font-weight: 600;
}

/* Date values */
[data-type="date"] {
  color: #6366f1;
  font-size: 0.85rem;
}

.modal-footer {
  padding: 12px 20px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid #e2e8f0;
  background: #f8fafc;
  border-radius: 0 0 20px 20px;
}

.modal-btn-primary {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  border-radius: 8px;
  background: #4f46e5;
  color: white;
  font-weight: 500;
  font-size: 0.875rem;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.modal-btn-primary:hover {
  background: #4338ca;
}

.close-btn {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.2s;
}

.close-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: rotate(90deg);
}

/* Scrollbar */
.modal-body::-webkit-scrollbar {
  width: 4px;
}

.modal-body::-webkit-scrollbar-track {
  background: transparent;
}

.modal-body::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 2px;
}

/* Animation */
.view-modal {
  animation: modalEnter 0.2s ease-out;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #1e293b;
  font-size: 0.9rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 6px;
  font-size: 0.9rem;
  background: linear-gradient(145deg, #f8fafc, #ffffff);
  box-shadow: inset 2px 2px 5px #d1d9e6,
              inset -2px -2px 5px #ffffff;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.1);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.modal-actions button {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.modal-actions button[type="submit"] {
  background: #4f46e5;
  color: white;
  border: none;
}

.modal-actions button[type="button"] {
  background: #f1f5f9;
  border: none;
}

/* Enhanced Modal Styles */
.modal-overlay {
  animation: fadeIn 0.2s ease;
}

.modal-content {
  animation: slideIn 0.3s ease;
}

.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-row .form-group {
  flex: 1;
}

.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  min-height: 100px;
  resize: vertical;
}

.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  background: white;
}

/* Enhanced button animations */
.action-btn {
  position: relative;
  overflow: hidden;
}

.action-btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.3s, height 0.3s;
}

.action-btn:hover::after {
  width: 150%;
  height: 150%;
}

.action-btn.view:hover {
  background: #ecfdf5;
  transform: translateY(-2px);
}

.action-btn.delete:hover {
  background: #fee2e2;
  transform: translateY(-2px);
}

/* Modal transitions */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { 
    opacity: 0;
    transform: translateY(-20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

/* Form field animations */
.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Update table wrapper styles to match PO table */
.table-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 0.5rem;
  position: relative;
}


/* Optimize scrollbar styling */
.table-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.table-scroll::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.table-scroll::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}

/* Update table styles */
.products-table {
  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
  border-spacing: 0;
}

/* Update header styles */
.products-table thead {
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
}

.products-table th {
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: left;
  color: #4b5563;
  background: #f8fafc;
  border-bottom: 1px solid #e5e7eb;
  position: relative;
  padding-right: 1.5rem;
}

.products-table th::after {
  content: '';
  position: absolute;
  right: 0;
  top: 25%;
  height: 50%;
  width: 4px;
  background: #e2e8f0;
  cursor: col-resize;
  opacity: 0;
  transition: opacity 0.2s;
}

.products-table th:hover::after {
  opacity: 1;
}

/* Update cell styles */
.products-table td {
  padding: 0.75rem;
  font-size: 0.875rem;
  color: #4b5563;
  border-bottom: 1px solid #e5e7eb;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Add hover effect */
.products-table tbody tr:hover {
  background-color: #f9fafb;
}

/* Update filters card */
.filters-card {
  padding: 0.5rem;
  background: white;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  height: auto;
  min-height: 3.5rem;
}

/* Cool Animations */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.loading-state {
  background: linear-gradient(
    90deg,
    #f0f4f8 0%,
    #e2e8f0 50%,
    #f0f4f8 100%
  );
  background-size: 1000px 100%;
  animation: shimmer 2s infinite;
}

/* New feature: Row highlight on hover */
.products-table tr {
  transition: all 0.2s ease;
}

.products-table tbody tr:hover {
  background: linear-gradient(90deg, #f8fafc 0%, #eef2ff 100%);
  transform: scale(1.002);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* New feature: Selected row highlight */
.products-table tr.selected {
  background: linear-gradient(90deg, #eef2ff 0%, #e0e7ff 100%);
}

/* New feature: Column sorting indicators */
.products-table th.sortable {
  cursor: pointer;
}

.products-table th.sortable::before {
  content: '↕️';
  position: absolute;
  right: 0.5rem;
  opacity: 0.3;
}

.products-table th.sorted-asc::before {
  content: '↑';
  opacity: 1;
}

.products-table th.sorted-desc::before {
  content: '↓';
  opacity: 1;
}

/* New feature: Quick actions toolbar */
.quick-actions {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  background: linear-gradient(90deg, #f8fafc 0%, #f1f5f9 100%);
  border-bottom: 1px solid #e2e8f0;
}

.quick-action-btn {
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
  background: white;
  border: 1px solid #e2e8f0;
  color: #4b5563;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.quick-action-btn:hover {
  background: #f8fafc;
  border-color: #cbd5e1;
  transform: translateY(-1px);
}

/* New feature: Batch selection indicator */
.selection-indicator {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: #4f46e5;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 999px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 50;
}

.selection-indicator.visible {
  opacity: 1;
}

/* New feature: Column visibility toggle dropdown */
.column-visibility {
  position: relative;
  display: inline-block;
}

.column-toggle-btn {
  padding: 0.5rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  cursor: pointer;
}

.column-list {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 40;
}

.column-list label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

/* New feature: Inline editing styles */
.editable-cell {
  position: relative;
}

.editable-cell input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0.75rem;
  border: 2px solid #4f46e5;
  border-radius: 4px;
  background: white;
  z-index: 20;
}

/* New feature: Row expansion animation */
.expandable-row {
  transition: all 0.3s ease;
}

.expandable-row.expanded {
  background: #f8fafc;
}

.row-details {
  padding: 1rem;
  background: white;
  border-top: 1px solid #e2e8f0;
  animation: slideDown 0.3s ease;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Enhanced table styling */
.products-table {
  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
}

.products-table th {
  background: linear-gradient(145deg, #f8fafc, #f1f5f9);
  font-weight: 600;
  color: #1e293b;
  transition: background-color 0.2s;
}

.products-table tr:hover {
  background: linear-gradient(90deg, #f8fafc 0%, #eef2ff 100%);
  transition: all 0.2s ease;
}

/* Enhanced modal styling */
.modal-content {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 2rem;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.modal-content h2 {
  color: #1e293b;
  font-size: 1.5rem;
  font-weight: 600;
}

.form-group label {
  color: #1e293b;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  color: #1e293b;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
  outline: none;
}

/* View mode styling */
.view-mode {
  padding: 20px;
}

.form-row {
  margin-bottom: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #475569;
}

.form-input {
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #1e293b;
  width: 100%;
  transition: all 0.2s;
}

.form-input:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.form-input:disabled {
  background-color: #f8fafc;
  cursor: not-allowed;
}

/* Keep existing modal footer styles */
.modal-footer {
  padding: 16px 24px;
  background: white;
  border-top: 1px solid #e2e8f0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

/* Enhanced action buttons */
.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.action-btn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.2s;
}

.action-btn.edit {
  background: linear-gradient(145deg, #eef2ff, #e0e7ff);
  color: #4f46e5;
}

.action-btn.view {
  background: linear-gradient(145deg, #ecfdf5, #d1fae5);
  color: #059669;
}

.action-btn.delete {
  background: linear-gradient(145deg, #fef2f2, #fee2e2);
  color: #dc2626;
}

.action-btn:hover {
  transform: translateY(-2px);
  filter: brightness(0.95);
}

/* Modal actions */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.modal-actions button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s;
}

.modal-actions button[type="submit"] {
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  color: white;
  border: none;
}

.modal-actions button[type="button"] {
  background: #f1f5f9;
  color: #64748b;
  border: none;
}

.modal-actions button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Status badge enhancements */
.status-badge {
  padding: 0.4rem 1rem;
  border-radius: 999px;
  font-size: 0.75rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.status-in-stock {
  background: linear-gradient(145deg, #dcfce7, #bbf7d0);
  color: #16a34a;
}

.status-low-stock {
  background: linear-gradient(145deg, #fef3c7, #fde68a);
  color: #d97706;
}

.status-out-of-stock {
  background: linear-gradient(145deg, #fee2e2, #fecaca);
  color: #dc2626;
}

/* Form row layout */
.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-row .form-group {
  flex: 1;
  margin-bottom: 0;
}

/* Loading state enhancement */
.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  gap: 1rem;
}

.loading-spinner {
  border: 3px solid #e2e8f0;
  border-top: 3px solid #4f46e5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.master-type-cards {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 0.25rem;
  padding: 0;
  height: 50px;
}

.master-type-card {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  gap: 0.75rem;
  background: white;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.master-type-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(79, 70, 229, 0.1);
}

.master-type-card.active {
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  border-color: transparent;
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.2);
}

.master-type-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: rgba(79, 70, 229, 0.1);
  border-radius: 8px;
}

.master-type-card h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.master-type-card.active h3 {
  color: white;
}

/* Style the table header actions */
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.25rem;
  background: white;
  border-bottom: 1px solid #e2e8f0;
}

.header-actions {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.action-btn-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.action-btn-header:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.download-btn {
  background: linear-gradient(145deg, #e0e7ff, #c7d2fe);
  color: #4f46e5;
  border-color: #818cf8;
}

.download-btn:hover {
  background: linear-gradient(145deg, #c7d2fe, #a5b4fc);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.1);
}

.upload-btn {
  background: linear-gradient(145deg, #dcfce7, #bbf7d0);
  color: #059669;
  border-color: #34d399;
}

.upload-btn:hover {
  background: linear-gradient(145deg, #bbf7d0, #86efac);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(5, 150, 105, 0.1);
}

.clear-btn {
  background: linear-gradient(145deg, #fee2e2, #fecaca);
  color: #dc2626;
  border-color: #f87171;
}

.clear-btn:hover {
  background: linear-gradient(145deg, #fecaca, #fca5a5);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(220, 38, 38, 0.1);
}

.table-container {
  flex: 1;
  overflow-y: auto; /* Allow vertical scrolling only in table */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Customize scrollbar for table */
.table-container::-webkit-scrollbar {
  width: 6px;
}

.table-container::-webkit-scrollbar-track {
  background: transparent;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: rgba(203, 213, 225, 0.4);
  border-radius: 3px;
}

/* For Firefox */
.table-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(203, 213, 225, 0.4) transparent;
}

/* Remove any conflicting styles */
.action-btn,
.action-buttons {
  display: none !important;
}

/* Update form styles to match previous layout */
.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #475569;
}

.form-group input,
.form-group select {
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #1e293b;
  width: 100%;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.modal-content form {
  padding: 20px;
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
  margin: 1rem;
  min-height: 300px;
}

.no-data-icon {
  width: 120px;
  height: 120px;
  margin-bottom: 1.5rem;
  color: #94a3b8;
  opacity: 0.8;
}

.no-data h3 {
  color: #1e293b;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.no-data p {
  color: #64748b;
  font-size: 0.875rem;
  max-width: 300px;
  line-height: 1.5;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.no-data-icon {
  animation: float 3s ease-in-out infinite;
}

/* Enhanced Edit Modal Styles */
.edit-modal {
  max-width: 800px;
  width: 95%;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 20px 40px -12px rgba(0, 0, 0, 0.15);
}

.edit-modal .modal-body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 20px;
  max-height: 70vh;
  overflow-y: auto;
}

.edit-modal .detail-item {
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: #f8fafc;
  transition: all 0.2s;
}

.edit-modal .detail-item:hover {
  border-color: #cbd5e1;
}

.edit-modal .form-group {
  margin: 0;
}

.edit-modal .form-group label {
  display: block;
  font-size: 0.75rem;
  font-weight: 600;
  color: #64748b;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.edit-modal .form-group input,
.edit-modal .form-group select,
.edit-modal .form-group textarea {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  background: white;
  color: #1e293b;
  transition: all 0.2s;
}

.edit-modal .form-group input:focus,
.edit-modal .form-group select:focus,
.edit-modal .form-group textarea:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.edit-modal .form-group input:disabled {
  background-color: #f8fafc;
  cursor: not-allowed;
  color: #64748b;
}

/* Special field styles */
.edit-modal .form-group input[type="number"] {
  font-family: 'SF Mono', 'Roboto Mono', monospace;
}

.edit-modal .form-group select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

/* Status field specific styles */
.edit-modal .form-group select[data-type="status"] {
  font-weight: 500;
}

.edit-modal .form-group select[data-type="status"] option {
  font-weight: 500;
}

/* Price field specific styles */
.edit-modal .form-group input[data-type="price"] {
  font-family: 'SF Mono', 'Roboto Mono', monospace;
  color: #059669;
}

/* Date field specific styles */
.edit-modal .form-group input[type="date"] {
  color: #6366f1;
}

/* Animation for edit modal */
.edit-modal {
  animation: modalEnter 0.2s ease-out;
}

@keyframes modalEnter {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .edit-modal .modal-body {
    grid-template-columns: 1fr;
  }
}

/* Add Record Button Style */
.action-btn-header.add-btn {
  background: linear-gradient(145deg, #4f46e5, #4338ca);
  color: white;
  border-color: #4338ca;
}

.action-btn-header.add-btn:hover {
  background: linear-gradient(145deg, #4338ca, #3730a3);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.1);
}

/* Modal Alert Styles */
.modal-alert-overlay {
  position: fixed; /* Change from absolute to fixed */
  inset: 0;
  background: rgba(15, 23, 42, 0.65);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100; /* Increase z-index to be higher than modal */
}

.modal-alert {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.modal-alert h3 {
  margin: 0 0 1rem 0;
  color: #0f172a;
  font-size: 1.125rem;
  font-weight: 600;
}

.changes-list {
  background: #f8fafc;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.change-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e2e8f0;
}

.change-item:last-child {
  border-bottom: none;
}

.field-name {
  font-weight: 500;
  color: #64748b;
}

.change-values {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.old-value {
  color: #ef4444;
  text-decoration: line-through;
}

.arrow {
  color: #64748b;
}

.new-value {
  color: #22c55e;
  font-weight: 500;
}

.alert-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}

.non-editable .detail-input {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
}

.readonly-badge {
  font-size: 0.7em;
  background-color: #e0e0e0;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  color: #666;
}

.detail-label {
  display: flex;
  align-items: center;
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(15, 23, 42, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.edit-modal, .view-modal {
  width: 90%;
  max-width: 700px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid #e2e8f0;
  background: #f8fafc;
}

.modal-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.modal-body {
  padding: 1.5rem;
  overflow-y: auto;
  max-height: calc(80vh - 130px);
}

.modal-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  border-top: 1px solid #e2e8f0;
  background: #f8fafc;
}

/* Enhanced Form Controls for Edit Modal */
.edit-form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.25rem;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #334155;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 0.875rem;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  background-color: #fff;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.required-mark {
  color: #ef4444;
  margin-left: 0.25rem;
}

.form-control.is-invalid {
  border-color: #ef4444;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ef4444' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ef4444' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 16px;
}

.error-message {
  color: #ef4444;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.help-text {
  display: block;
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: #64748b;
}

/* View Modal Specific Styling */
.view-data {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.data-item {
  margin-bottom: 1rem;
}

.data-item .label {
  font-size: 0.75rem;
  color: #64748b;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.data-item .value {
  font-size: 0.9rem;
  color: #0f172a;
  padding: 0.625rem;
  background: #f8fafc;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  min-height: 2.5rem;
  word-break: break-word;
}

/* Modal Buttons */
.modal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.625rem 1.25rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
  height: 40px;
  min-width: 100px;
}

.modal-btn-primary {
  background: linear-gradient(145deg, #3b82f6, #2563eb);
  color: white;
  border: none;
}

.modal-btn-primary:hover {
  background: linear-gradient(145deg, #2563eb, #1d4ed8);
  transform: translateY(-1px);
}

.modal-btn-secondary {
  background: white;
  color: #475569;
  border: 1px solid #cbd5e1;
}

.modal-btn-secondary:hover {
  background: #f1f5f9;
  border-color: #94a3b8;
}

.modal-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.close-btn {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #64748b;
  transition: all 0.2s;
}

.close-btn:hover {
  background: #f1f5f9;
  color: #0f172a;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .edit-form, .view-data {
    grid-template-columns: 1fr;
  }
  
  .modal-content {
    width: 95%;
  }
  
  .modal-header h2 {
    font-size: 1.1rem;
  }
}

/* Status badges */
[data-type="status"] {
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 0.8rem;
  font-weight: 500;
}

.status-active {
  background: rgba(16, 185, 129, 0.1);
  color: #059669;
}

.status-inactive {
  background: rgba(239, 68, 68, 0.1);
  color: #dc2626;
}

.status-pending {
  background: rgba(245, 158, 11, 0.1);
  color: #d97706;
}

/* Price values */
[data-type="price"] {
  font-family: 'SF Mono', 'Roboto Mono', monospace;
  color: #059669;
  font-weight: 600;
}

/* Date values */
[data-type="date"] {
  color: #6366f1;
  font-size: 0.85rem;
}

.modal-footer {
  padding: 12px 20px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid #e2e8f0;
  background: #f8fafc;
  border-radius: 0 0 20px 20px;
}

.modal-btn-primary {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  border-radius: 8px;
  background: #4f46e5;
  color: white;
  font-weight: 500;
  font-size: 0.875rem;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.modal-btn-primary:hover {
  background: #4338ca;
}

.close-btn {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.2s;
}

.close-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: rotate(90deg);
}

/* Scrollbar */
.modal-body::-webkit-scrollbar {
  width: 4px;
}

.modal-body::-webkit-scrollbar-track {
  background: transparent;
}

.modal-body::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 2px;
}

/* Animation */
.view-modal {
  animation: modalEnter 0.2s ease-out;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #1e293b;
  font-size: 0.9rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 6px;
  font-size: 0.9rem;
  background: linear-gradient(145deg, #f8fafc, #ffffff);
  box-shadow: inset 2px 2px 5px #d1d9e6,
              inset -2px -2px 5px #ffffff;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.1);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.modal-actions button {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.modal-actions button[type="submit"] {
  background: #4f46e5;
  color: white;
  border: none;
}

.modal-actions button[type="button"] {
  background: #f1f5f9;
  border: none;
}

/* Enhanced Modal Styles */
.modal-overlay {
  animation: fadeIn 0.2s ease;
}

.modal-content {
  animation: slideIn 0.3s ease;
}

.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-row .form-group {
  flex: 1;
}

.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  min-height: 100px;
  resize: vertical;
}

.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  background: white;
}

/* Enhanced button animations */
.action-btn {
  position: relative;
  overflow: hidden;
}

.action-btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.3s, height 0.3s;
}

.action-btn:hover::after {
  width: 150%;
  height: 150%;
}

.action-btn.view:hover {
  background: #ecfdf5;
  transform: translateY(-2px);
}

.action-btn.delete:hover {
  background: #fee2e2;
  transform: translateY(-2px);
}

/* Modal transitions */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { 
    opacity: 0;
    transform: translateY(-20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

/* Form field animations */
.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Update table wrapper styles to match PO table */
.table-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 0.5rem;
  position: relative;
}


/* Optimize scrollbar styling */
.table-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.table-scroll::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.table-scroll::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}

/* Update table styles */
.products-table {
  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
  border-spacing: 0;
}

/* Update header styles */
.products-table thead {
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
}

.products-table th {
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: left;
  color: #4b5563;
  background: #f8fafc;
  border-bottom: 1px solid #e5e7eb;
  position: relative;
  padding-right: 1.5rem;
}

.products-table th::after {
  content: '';
  position: absolute;
  right: 0;
  top: 25%;
  height: 50%;
  width: 4px;
  background: #e2e8f0;
  cursor: col-resize;
  opacity: 0;
  transition: opacity 0.2s;
}

.products-table th:hover::after {
  opacity: 1;
}

/* Update cell styles */
.products-table td {
  padding: 0.75rem;
  font-size: 0.875rem;
  color: #4b5563;
  border-bottom: 1px solid #e5e7eb;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Add hover effect */
.products-table tbody tr:hover {
  background-color: #f9fafb;
}

/* Update filters card */
.filters-card {
  padding: 0.5rem;
  background: white;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  height: auto;
  min-height: 3.5rem;
}

/* Cool Animations */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.loading-state {
  background: linear-gradient(
    90deg,
    #f0f4f8 0%,
    #e2e8f0 50%,
    #f0f4f8 100%
  );
  background-size: 1000px 100%;
  animation: shimmer 2s infinite;
}

/* New feature: Row highlight on hover */
.products-table tr {
  transition: all 0.2s ease;
}

.products-table tbody tr:hover {
  background: linear-gradient(90deg, #f8fafc 0%, #eef2ff 100%);
  transform: scale(1.002);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* New feature: Selected row highlight */
.products-table tr.selected {
  background: linear-gradient(90deg, #eef2ff 0%, #e0e7ff 100%);
}

/* New feature: Column sorting indicators */
.products-table th.sortable {
  cursor: pointer;
}

.products-table th.sortable::before {
  content: '↕️';
  position: absolute;
  right: 0.5rem;
  opacity: 0.3;
}

.products-table th.sorted-asc::before {
  content: '↑';
  opacity: 1;
}

.products-table th.sorted-desc::before {
  content: '↓';
  opacity: 1;
}

/* New feature: Quick actions toolbar */
.quick-actions {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  background: linear-gradient(90deg, #f8fafc 0%, #f1f5f9 100%);
  border-bottom: 1px solid #e2e8f0;
}

.quick-action-btn {
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
  background: white;
  border: 1px solid #e2e8f0;
  color: #4b5563;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.quick-action-btn:hover {
  background: #f8fafc;
  border-color: #cbd5e1;
  transform: translateY(-1px);
}

/* New feature: Batch selection indicator */
.selection-indicator {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: #4f46e5;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 999px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 50;
}

.selection-indicator.visible {
  opacity: 1;
}

/* New feature: Column visibility toggle dropdown */
.column-visibility {
  position: relative;
  display: inline-block;
}

.column-toggle-btn {
  padding: 0.5rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  cursor: pointer;
}

.column-list {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 40;
}

.column-list label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

/* New feature: Inline editing styles */
.editable-cell {
  position: relative;
}

.editable-cell input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0.75rem;
  border: 2px solid #4f46e5;
  border-radius: 4px;
  background: white;
  z-index: 20;
}

/* New feature: Row expansion animation */
.expandable-row {
  transition: all 0.3s ease;
}

.expandable-row.expanded {
  background: #f8fafc;
}

.row-details {
  padding: 1rem;
  background: white;
  border-top: 1px solid #e2e8f0;
  animation: slideDown 0.3s ease;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Enhanced table styling */
.products-table {
  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
}

.products-table th {
  background: linear-gradient(145deg, #f8fafc, #f1f5f9);
  font-weight: 600;
  color: #1e293b;
  transition: background-color 0.2s;
}

.products-table tr:hover {
  background: linear-gradient(90deg, #f8fafc 0%, #eef2ff 100%);
  transition: all 0.2s ease;
}

/* Enhanced modal styling */
.modal-content {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 2rem;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.modal-content h2 {
  color: #1e293b;
  font-size: 1.5rem;
  font-weight: 600;
}

.form-group label {
  color: #1e293b;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  color: #1e293b;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
  outline: none;
}

/* View mode styling */
.view-mode {
  padding: 20px;
}

.form-row {
  margin-bottom: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #475569;
}

.form-input {
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #1e293b;
  width: 100%;
  transition: all 0.2s;
}

.form-input:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.form-input:disabled {
  background-color: #f8fafc;
  cursor: not-allowed;
}

/* Keep existing modal footer styles */
.modal-footer {
  padding: 16px 24px;
  background: white;
  border-top: 1px solid #e2e8f0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

/* Enhanced action buttons */
.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.action-btn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.2s;
}

.action-btn.edit {
  background: linear-gradient(145deg, #eef2ff, #e0e7ff);
  color: #4f46e5;
}

.action-btn.view {
  background: linear-gradient(145deg, #ecfdf5, #d1fae5);
  color: #059669;
}

.action-btn.delete {
  background: linear-gradient(145deg, #fef2f2, #fee2e2);
  color: #dc2626;
}

.action-btn:hover {
  transform: translateY(-2px);
  filter: brightness(0.95);
}

/* Modal actions */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.modal-actions button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s;
}

.modal-actions button[type="submit"] {
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  color: white;
  border: none;
}

.modal-actions button[type="button"] {
  background: #f1f5f9;
  color: #64748b;
  border: none;
}

.modal-actions button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Status badge enhancements */
.status-badge {
  padding: 0.4rem 1rem;
  border-radius: 999px;
  font-size: 0.75rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.status-in-stock {
  background: linear-gradient(145deg, #dcfce7, #bbf7d0);
  color: #16a34a;
}

.status-low-stock {
  background: linear-gradient(145deg, #fef3c7, #fde68a);
  color: #d97706;
}

.status-out-of-stock {
  background: linear-gradient(145deg, #fee2e2, #fecaca);
  color: #dc2626;
}

/* Form row layout */
.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-row .form-group {
  flex: 1;
  margin-bottom: 0;
}

/* Loading state enhancement */
.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  gap: 1rem;
}

.loading-spinner {
  border: 3px solid #e2e8f0;
  border-top: 3px solid #4f46e5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.master-type-cards {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 0.25rem;
  padding: 0;
  height: 50px;
}

.master-type-card {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  gap: 0.75rem;
  background: white;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.master-type-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(79, 70, 229, 0.1);
}

.master-type-card.active {
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  border-color: transparent;
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.2);
}

.master-type-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: rgba(79, 70, 229, 0.1);
  border-radius: 8px;
}

.master-type-card h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.master-type-card.active h3 {
  color: white;
}

/* Style the table header actions */
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.25rem;
  background: white;
  border-bottom: 1px solid #e2e8f0;
}

.header-actions {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.action-btn-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.action-btn-header:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.download-btn {
  background: linear-gradient(145deg, #e0e7ff, #c7d2fe);
  color: #4f46e5;
  border-color: #818cf8;
}

.download-btn:hover {
  background: linear-gradient(145deg, #c7d2fe, #a5b4fc);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.1);
}

.upload-btn {
  background: linear-gradient(145deg, #dcfce7, #bbf7d0);
  color: #059669;
  border-color: #34d399;
}

.upload-btn:hover {
  background: linear-gradient(145deg, #bbf7d0, #86efac);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(5, 150, 105, 0.1);
}

.clear-btn {
  background: linear-gradient(145deg, #fee2e2, #fecaca);
  color: #dc2626;
  border-color: #f87171;
}

.clear-btn:hover {
  background: linear-gradient(145deg, #fecaca, #fca5a5);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(220, 38, 38, 0.1);
}

.table-container {
  flex: 1;
  overflow-y: auto; /* Allow vertical scrolling only in table */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Customize scrollbar for table */
.table-container::-webkit-scrollbar {
  width: 6px;
}

.table-container::-webkit-scrollbar-track {
  background: transparent;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: rgba(203, 213, 225, 0.4);
  border-radius: 3px;
}

/* For Firefox */
.table-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(203, 213, 225, 0.4) transparent;
}

/* Remove any conflicting styles */
.action-btn,
.action-buttons {
  display: none !important;
}

/* Update form styles to match previous layout */
.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #475569;
}

.form-group input,
.form-group select {
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #1e293b;
  width: 100%;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.modal-content form {
  padding: 20px;
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
  margin: 1rem;
  min-height: 300px;
}

.no-data-icon {
  width: 120px;
  height: 120px;
  margin-bottom: 1.5rem;
  color: #94a3b8;
  opacity: 0.8;
}

.no-data h3 {
  color: #1e293b;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.no-data p {
  color: #64748b;
  font-size: 0.875rem;
  max-width: 300px;
  line-height: 1.5;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.no-data-icon {
  animation: float 3s ease-in-out infinite;
}

/* Enhanced Edit Modal Styles */
.edit-modal {
  max-width: 800px;
  width: 95%;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 20px 40px -12px rgba(0, 0, 0, 0.15);
}

.edit-modal .modal-body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 20px;
  max-height: 70vh;
  overflow-y: auto;
}

.edit-modal .detail-item {
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: #f8fafc;
  transition: all 0.2s;
}

.edit-modal .detail-item:hover {
  border-color: #cbd5e1;
}

.edit-modal .form-group {
  margin: 0;
}

.edit-modal .form-group label {
  display: block;
  font-size: 0.75rem;
  font-weight: 600;
  color: #64748b;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.edit-modal .form-group input,
.edit-modal .form-group select,
.edit-modal .form-group textarea {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  background: white;
  color: #1e293b;
  transition: all 0.2s;
}

.edit-modal .form-group input:focus,
.edit-modal .form-group select:focus,
.edit-modal .form-group textarea:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.edit-modal .form-group input:disabled {
  background-color: #f8fafc;
  cursor: not-allowed;
  color: #64748b;
}

/* Special field styles */
.edit-modal .form-group input[type="number"] {
  font-family: 'SF Mono', 'Roboto Mono', monospace;
}

.edit-modal .form-group select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

/* Status field specific styles */
.edit-modal .form-group select[data-type="status"] {
  font-weight: 500;
}

.edit-modal .form-group select[data-type="status"] option {
  font-weight: 500;
}

/* Price field specific styles */
.edit-modal .form-group input[data-type="price"] {
  font-family: 'SF Mono', 'Roboto Mono', monospace;
  color: #059669;
}

/* Date field specific styles */
.edit-modal .form-group input[type="date"] {
  color: #6366f1;
}

/* Animation for edit modal */
.edit-modal {
  animation: modalEnter 0.2s ease-out;
}

@keyframes modalEnter {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .edit-modal .modal-body {
    grid-template-columns: 1fr;
  }
}

/* Add Record Button Style */
.action-btn-header.add-btn {
  background: linear-gradient(145deg, #4f46e5, #4338ca);
  color: white;
  border-color: #4338ca;
}

.action-btn-header.add-btn:hover {
  background: linear-gradient(145deg, #4338ca, #3730a3);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.1);
}

/* Modal Alert Styles */
.modal-alert-overlay {
  position: fixed; /* Change from absolute to fixed */
  inset: 0;
  background: rgba(15, 23, 42, 0.65);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100; /* Increase z-index to be higher than modal */
}

.modal-alert {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.modal-alert h3 {
  margin: 0 0 1rem 0;
  color: #0f172a;
  font-size: 1.125rem;
  font-weight: 600;
}

.changes-list {
  background: #f8fafc;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.change-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e2e8f0;
}

.change-item:last-child {
  border-bottom: none;
}

.field-name {
  font-weight: 500;
  color: #64748b;
}

.change-values {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.old-value {
  color: #ef4444;
  text-decoration: line-through;
}

.arrow {
  color: #64748b;
}

.new-value {
  color: #22c55e;
  font-weight: 500;
}

.alert-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}

.non-editable .detail-input {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
}

.readonly-badge {
  font-size: 0.7em;
  background-color: #e0e0e0;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  color: #666;
}

.detail-label {
  display: flex;
  align-items: center;
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(15, 23, 42, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.edit-modal, .view-modal {
  width: 90%;
  max-width: 700px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid #e2e8f0;
  background: #f8fafc;
}

.modal-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.modal-body {
  padding: 1.5rem;
  overflow-y: auto;
  max-height: calc(80vh - 130px);
}

.modal-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  border-top: 1px solid #e2e8f0;
  background: #f8fafc;
}

/* Enhanced Form Controls for Edit Modal */
.edit-form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.25rem;
}

.form-group {
  margin-bottom: 1.25rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #334155;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 0.875rem;
  border: 1px solid #cbd5e1;
  border-radius: 8px;
  background-color: #fff;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.required-mark {
  color: #ef4444;
  margin-left: 0.25rem;
}

.form-control.is-invalid {
  border-color: #ef4444;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ef4444' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ef4444' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 16px;
}

.error-message {
  color: #ef4444;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.help-text {
  display: block;
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: #64748b;
}

/* View Modal Specific Styling */
.view-data {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.data-item {
  margin-bottom: 1rem;
}

.data-item .label {
  font-size: 0.75rem;
  color: #64748b;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.data-item .value {
  font-size: 0.9rem;
  color: #0f172a;
  padding: 0.625rem;
  background: #f8fafc;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  min-height: 2.5rem;
  word-break: break-word;
}

/* Modal Buttons */
.modal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.625rem 1.25rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
  height: 40px;
  min-width: 100px;
}

.modal-btn-primary {
  background: linear-gradient(145deg, #3b82f6, #2563eb);
  color: white;
  border: none;
}

.modal-btn-primary:hover {
  background: linear-gradient(145deg, #2563eb, #1d4ed8);
  transform: translateY(-1px);
}

.modal-btn-secondary {
  background: white;
  color: #475569;
  border: 1px solid #cbd5e1;
}

.modal-btn-secondary:hover {
  background: #f1f5f9;
  border-color: #94a3b8;
}

.modal-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.close-btn {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #64748b;
  transition: all 0.2s;
}

.close-btn:hover {
  background: #f1f5f9;
  color: #0f172a;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .edit-form, .view-data {
    grid-template-columns: 1fr;
  }
  
  .modal-content {
    width: 95%;
  }
  
  .modal-header h2 {
    font-size: 1.1rem;
  }
}

/* Status badges */
[data-type="status"] {
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 0.8rem;
  font-weight: 500;
}

.status-active {
  background: rgba(16, 185, 129, 0.1);
  color: #059669;
}

.status-inactive {
  background: rgba(239, 68, 68, 0.1);
  color: #dc2626;
}

.status-pending {
  background: rgba(245, 158, 11, 0.1);
  color: #d97706;
}

/* Price values */
[data-type="price"] {
  font-family: 'SF Mono', 'Roboto Mono', monospace;
  color: #059669;
  font-weight: 600;
}

/* Date values */
[data-type="date"] {
  color: #6366f1;
  font-size: 0.85rem;
}

.modal-footer {
  padding: 12px 20px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid #e2e8f0;
  background: #f8fafc;
  border-radius: 0 0 20px 20px;
}

.modal-btn-primary {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  border-radius: 8px;
  background: #4f46e5;
  color: white;
  font-weight: 500;
  font-size: 0.875rem;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.modal-btn-primary:hover {
  background: #4338ca;
}

.close-btn {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: all 0.2s;
}

.close-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: rotate(90deg);
}

/* Scrollbar */
.modal-body::-webkit-scrollbar {
  width: 4px;
}

.modal-body::-webkit-scrollbar-track {
  background: transparent;
}

.modal-body::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 2px;
}

/* Animation */
.view-modal {
  animation: modalEnter 0.2s ease-out;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #1e293b;
  font-size: 0.9rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 6px;
  font-size: 0.9rem;
  background: linear-gradient(145deg, #f8fafc, #ffffff);
  box-shadow: inset 2px 2px 5px #d1d9e6,
              inset -2px -2px 5px #ffffff;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.1);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.modal-actions button {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.modal-actions button[type="submit"] {
  background: #4f46e5;
  color: white;
  border: none;
}

.modal-actions button[type="button"] {
  background: #f1f5f9;
  border: none;
}

/* Enhanced Modal Styles */
.modal-overlay {
  animation: fadeIn 0.2s ease;
}

.modal-content {
  animation: slideIn 0.3s ease;
}

.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-row .form-group {
  flex: 1;
}

.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  min-height: 100px;
  resize: vertical;
}

.form-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  background: white;
}

/* Enhanced button animations */
.action-btn {
  position: relative;
  overflow: hidden;
}

.action-btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.3s, height 0.3s;
}

.action-btn:hover::after {
  width: 150%;
  height: 150%;
}

.action-btn.view:hover {
  background: #ecfdf5;
  transform: translateY(-2px);
}

.action-btn.delete:hover {
  background: #fee2e2;
  transform: translateY(-2px);
}

/* Modal transitions */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { 
    opacity: 0;
    transform: translateY(-20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

/* Form field animations */
.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Update table wrapper styles to match PO table */
.table-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 0.5rem;
  position: relative;
}


/* Optimize scrollbar styling */
.table-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.table-scroll::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.table-scroll::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}

/* Update table styles */
.products-table {
  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
  border-spacing: 0;
}

/* Update header styles */
.products-table thead {
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
}

.products-table th {
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: left;
  color: #4b5563;
  background: #f8fafc;
  border-bottom: 1px solid #e5e7eb;
  position: relative;
  padding-right: 1.5rem;
}

.products-table th::after {
  content: '';
  position: absolute;
  right: 0;
  top: 25%;
  height: 50%;
  width: 4px;
  background: #e2e8f0;
  cursor: col-resize;
  opacity: 0;
  transition: opacity 0.2s;
}

.products-table th:hover::after {
  opacity: 1;
}

/* Update cell styles */
.products-table td {
  padding: 0.75rem;
  font-size: 0.875rem;
  color: #4b5563;
  border-bottom: 1px solid #e5e7eb;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Add hover effect */
.products-table tbody tr:hover {
  background-color: #f9fafb;
}

/* Update filters card */
.filters-card {
  padding: 0.5rem;
  background: white;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  height: auto;
  min-height: 3.5rem;
}

/* Cool Animations */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.loading-state {
  background: linear-gradient(
    90deg,
    #f0f4f8 0%,
    #e2e8f0 50%,
    #f0f4f8 100%
  );
  background-size: 1000px 100%;
  animation: shimmer 2s infinite;
}

/* New feature: Row highlight on hover */
.products-table tr {
  transition: all 0.2s ease;
}

.products-table tbody tr:hover {
  background: linear-gradient(90deg, #f8fafc 0%, #eef2ff 100%);
  transform: scale(1.002);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* New feature: Selected row highlight */
.products-table tr.selected {
  background: linear-gradient(90deg, #eef2ff 0%, #e0e7ff 100%);
}

/* New feature: Column sorting indicators */
.products-table th.sortable {
  cursor: pointer;
}

.products-table th.sortable::before {
  content: '↕️';
  position: absolute;
  right: 0.5rem;
  opacity: 0.3;
}

.products-table th.sorted-asc::before {
  content: '↑';
  opacity: 1;
}

.products-table th.sorted-desc::before {
  content: '↓';
  opacity: 1;
}

/* New feature: Quick actions toolbar */
.quick-actions {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  background: linear-gradient(90deg, #f8fafc 0%, #f1f5f9 100%);
  border-bottom: 1px solid #e2e8f0;
}

.quick-action-btn {
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
  background: white;
  border: 1px solid #e2e8f0;
  color: #4b5563;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.quick-action-btn:hover {
  background: #f8fafc;
  border-color: #cbd5e1;
  transform: translateY(-1px);
}

/* New feature: Batch selection indicator */
.selection-indicator {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: #4f46e5;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 999px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 50;
}

.selection-indicator.visible {
  opacity: 1;
}

/* New feature: Column visibility toggle dropdown */
.column-visibility {
  position: relative;
  display: inline-block;
}

.column-toggle-btn {
  padding: 0.5rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  cursor: pointer;
}

.column-list {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 40;
}

.column-list label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

/* New feature: Inline editing styles */
.editable-cell {
  position: relative;
}

.editable-cell input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0.75rem;
  border: 2px solid #4f46e5;
  border-radius: 4px;
  background: white;
  z-index: 20;
}

/* New feature: Row expansion animation */
.expandable-row {
  transition: all 0.3s ease;
}

.expandable-row.expanded {
  background: #f8fafc;
}

.row-details {
  padding: 1rem;
  background: white;
  border-top: 1px solid #e2e8f0;
  animation: slideDown 0.3s ease;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Enhanced table styling */
.products-table {
  width: 100%;
  min-width: 1200px;
  border-collapse: collapse;
}

.products-table th {
  background: linear-gradient(145deg, #f8fafc, #f1f5f9);
  font-weight: 600;
  color: #1e293b;
  transition: background-color 0.2s;
}

.products-table tr:hover {
  background: linear-gradient(90deg, #f8fafc 0%, #eef2ff 100%);
  transition: all 0.2s ease;
}

/* Enhanced modal styling */
.modal-content {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  padding: 2rem;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.modal-content h2 {
  color: #1e293b;
  font-size: 1.5rem;
  font-weight: 600;
}

.form-group label {
  color: #1e293b;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  color: #1e293b;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
  outline: none;
}

/* View mode styling */
.view-mode {
  padding: 20px;
}

.form-row {
  margin-bottom: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #475569;
}

.form-input {
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #1e293b;
  width: 100%;
  transition: all 0.2s;
}

.form-input:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.form-input:disabled {
  background-color: #f8fafc;
  cursor: not-allowed;
}

/* Keep existing modal footer styles */
.modal-footer {
  padding: 16px 24px;
  background: white;
  border-top: 1px solid #e2e8f0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

/* Enhanced action buttons */
.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.action-btn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 0.2s;
}

.action-btn.edit {
  background: linear-gradient(145deg, #eef2ff, #e0e7ff);
  color: #4f46e5;
}

.action-btn.view {
  background: linear-gradient(145deg, #ecfdf5, #d1fae5);
  color: #059669;
}

.action-btn.delete {
  background: linear-gradient(145deg, #fef2f2, #fee2e2);
  color: #dc2626;
}

.action-btn:hover {
  transform: translateY(-2px);
  filter: brightness(0.95);
}

/* Modal actions */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.modal-actions button {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s;
}

.modal-actions button[type="submit"] {
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  color: white;
  border: none;
}

.modal-actions button[type="button"] {
  background: #f1f5f9;
  color: #64748b;
  border: none;
}

.modal-actions button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Status badge enhancements */
.status-badge {
  padding: 0.4rem 1rem;
  border-radius: 999px;
  font-size: 0.75rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.status-in-stock {
  background: linear-gradient(145deg, #dcfce7, #bbf7d0);
  color: #16a34a;
}

.status-low-stock {
  background: linear-gradient(145deg, #fef3c7, #fde68a);
  color: #d97706;
}

.status-out-of-stock {
  background: linear-gradient(145deg, #fee2e2, #fecaca);
  color: #dc2626;
}

/* Form row layout */
.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-row .form-group {
  flex: 1;
  margin-bottom: 0;
}

/* Loading state enhancement */
.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  gap: 1rem;
}

.loading-spinner {
  border: 3px solid #e2e8f0;
  border-top: 3px solid #4f46e5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.master-type-cards {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 0.25rem;
  padding: 0;
  height: 50px;
}

.master-type-card {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  gap: 0.75rem;
  background: white;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #e2e8f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.master-type-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(79, 70, 229, 0.1);
}

.master-type-card.active {
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  border-color: transparent;
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.2);
}

.master-type-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: rgba(79, 70, 229, 0.1);
  border-radius: 8px;
}

.master-type-card h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.master-type-card.active h3 {
  color: white;
}

/* Style the table header actions */
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.25rem;
  background: white;
  border-bottom: 1px solid #e2e8f0;
}

.header-actions {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.action-btn-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.action-btn-header:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.download-btn {
  background: linear-gradient(145deg, #e0e7ff, #c7d2fe);
  color: #4f46e5;
  border-color: #818cf8;
}

.download-btn:hover {
  background: linear-gradient(145deg, #c7d2fe, #a5b4fc);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.1);
}

.upload-btn {
  background: linear-gradient(145deg, #dcfce7, #bbf7d0);
  color: #059669;
  border-color: #34d399;
}

.upload-btn:hover {
  background: linear-gradient(145deg, #bbf7d0, #86efac);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(5, 150, 105, 0.1);
}

.clear-btn {
  background: linear-gradient(145deg, #fee2e2, #fecaca);
  color: #dc2626;
  border-color: #f87171;
}

.clear-btn:hover {
  background: linear-gradient(145deg, #fecaca, #fca5a5);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(220, 38, 38, 0.1);
}

.table-container {
  flex: 1;
  overflow-y: auto; /* Allow vertical scrolling only in table */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Customize scrollbar for table */
.table-container::-webkit-scrollbar {
  width: 6px;
}

.table-container::-webkit-scrollbar-track {
  background: transparent;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: rgba(203, 213, 225, 0.4);
  border-radius: 3px;
}

/* For Firefox */
.table-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(203, 213, 225, 0.4) transparent;
}

/* Remove any conflicting styles */
.action-btn,
.action-buttons {
  display: none !important;
}

/* Update form styles to match previous layout */
.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 0.875rem;
  font-weight: 600;
  color: #475569;
}

.form-group input,
.form-group select {
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  color: #1e293b;
  width: 100%;
  transition: all 0.2s;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.modal-content form {
  padding: 20px;
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  text-align: center;
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
  margin: 1rem;
  min-height: 300px;
}

.no-data-icon {
  width: 120px;
  height: 120px;
  margin-bottom: 1.5rem;
  color: #94a3b8;
  opacity: 0.8;
}

.no-data h3 {
  color: #1e293b;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.no-data p {
  color: #64748b;
  font-size: 0.875rem;
  max-width: 300px;
  line-height: 1.5;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.no-data-icon {
  animation: float 3s ease-in-out infinite;
}

/* Enhanced Edit Modal Styles */
.edit-modal {
  max-width: 800px;
  width: 95%;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 20px 40px -12px rgba(0, 0, 0, 0.15);
}

.edit-modal .modal-body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 20px;
  max-height: 70vh;
  overflow-y: auto;
}

.edit-modal .detail-item {
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: #f8fafc;
  transition: all 0.2s;
}

.edit-modal .detail-item:hover {
  border-color: #cbd5e1;
}

.edit-modal .form-group {
  margin: 0;
}

.edit-modal .form-group label {
  display: block;
  font-size: 0.75rem;
  font-weight: 600;
  color: #64748b;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.edit-modal .form-group input,
.edit-modal .form-group select,
.edit-modal .form-group textarea {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.875rem;
  background: white;
  color: #1e293b;
  transition: all 0.2s;
}

.edit-modal .form-group input:focus,
.edit-modal .form-group select:focus,
.edit-modal .form-group textarea:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
}

.edit-modal .form-group input:disabled {
  background-color: #f8fafc;
  cursor: not-allowed;
  color: #64748b;
}

/* Special field styles */
.edit-modal .form-group input[type="number"] {
  font-family: 'SF Mono', 'Roboto Mono', monospace;
}

.edit-modal .form-group select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

/* Status field specific styles */
.edit-modal .form-group select[data-type="status"] {
  font-weight: 500;
}

.edit-modal .form-group select[data-type="status"] option {
  font-weight: 500;
}

/* Price field specific styles */
.edit-modal .form-group input[data-type="price"] {
  font-family: 'SF Mono', 'Roboto Mono', monospace;
  color: #059669;
}

/* Date field specific styles */
.edit-modal .form-group input[type="date"] {
  color: #6366f1;
}

/* Animation for edit modal */
.edit-modal {
  animation: modalEnter 0.2s ease-out;
}

@keyframes modalEnter {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .edit-modal .modal-body {
    grid-template-columns: 1fr;
  }
}

/* Add Record Button Style */
.action-btn-header.add-btn {
  background: linear-gradient(145deg, #4f46e5, #4338ca);
  color: white;
  border-color: #4338ca;
}

.action-btn-header.add-btn:hover {
  background: linear-gradient(145deg, #4338ca, #3730a3);
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.1);
}

/* Modal Alert Styles */
.modal-alert-overlay {
  position: absolute;
  inset: 0;
  background: rgba(15, 23, 42, 0.65);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.modal-alert {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.modal-alert h3 {
  margin: 0 0 1rem 0;
  color: #0f172a;
  font-size: 1.125rem;
  font-weight: 600;
}

.changes-list {
  background: #f8fafc;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.change-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e2e8f0;
}

.change-item:last-child {
  border-bottom: none;
}

.field-name {
  font-weight: 500;
  color: #64748b;
}

.change-values {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
}

.old-value {
  color: #ef4444;
  text-decoration: line-through;
}

.arrow {
  color: #64748b;
}

.new-value {
  color: #22c55e;
  font-weight: 500;
}

.alert-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}

.non-editable .detail-input {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
}

.readonly-badge {
  font-size: 0.7em;
  background-color: #e0e0e0;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  color: #666;
}

.detail-label {
  display: flex;
  align-items: center;
}

.modal-overlay {
  z-index: 1000;
}

.alert-card {
  z-index: 1001; /* Ensure alert is always on top */
}

/* Add styles for the confirmation dialog */
.alert-card.warning {
  background-color: #fff3e0;
  border-color: #ffb74d;
}

.alert-card .message {
  white-space: pre-line; /* Preserve line breaks in the message */
  max-height: 200px;
  overflow-y: auto;
}

/* Style for the buttons in the alert */
.alert-card .buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 15px;
}

.alert-card button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.alert-card button.confirm {
  background-color: #2196f3;
  color: white;
}

.alert-card button.cancel {
  background-color: #e0e0e0;
  color: #424242;
}

/* Sub-types section styling - more compact version */
.sub-types-section {
  background: linear-gradient(to bottom, #ffffff, #f8fafc);
  border-radius: 10px;
  padding: 0.75rem;
  margin-top: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03), 
              0 1px 2px rgba(0, 0, 0, 0.06);
}

.sub-types-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 0.5rem;
}

.sub-type-card {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border: 1px solid #e2e8f0;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 36px;
  min-width: 160px;
  position: relative;
  overflow: hidden;
}

.sub-type-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: transparent;
  transition: all 0.2s ease;
}

.sub-type-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  position: relative;
  z-index: 1;
}

.sub-type-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.sub-type-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: #f1f5f9;
  transition: all 0.2s ease;
}

.sub-type-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #1e293b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Specific type styling */
.sub-type-card[data-type="UPLOAD_MASTER"]::before {
  background: #3b82f6;
}

.sub-type-card[data-type="CREDENTIALS_MASTER"]::before {
  background: #f59e0b;
}

.sub-type-card[data-type="SOLDTO_PRICING"]::before {
  background: #10b981;
}

.sub-type-card[data-type="DISABLED_SKUS"]::before {
  background: #ef4444;
}

/* Hover states */
.sub-type-card:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.08),
              0 2px 4px -1px rgba(0, 0, 0, 0.04);
  border-color: rgba(99, 102, 241, 0.2);
}

.sub-type-card:hover::before {
  height: 3px;
}

.sub-type-card:hover .sub-type-icon {
  transform: scale(1.05);
}

/* Active states with enhanced gradients */
.sub-type-card[data-type="UPLOAD_MASTER"].active {
  background: linear-gradient(145deg, #3b82f6, #2563eb);
  border-color: #2563eb;
  box-shadow: 0 4px 6px -1px rgba(59, 130, 246, 0.2);
}

.sub-type-card[data-type="CREDENTIALS_MASTER"].active {
  background: linear-gradient(145deg, #f59e0b, #d97706);
  border-color: #d97706;
  box-shadow: 0 4px 6px -1px rgba(245, 158, 11, 0.2);
}

.sub-type-card[data-type="SOLDTO_PRICING"].active {
  background: linear-gradient(145deg, #10b981, #059669);
  border-color: #059669;
  box-shadow: 0 4px 6px -1px rgba(16, 185, 129, 0.2);
}

.sub-type-card[data-type="DISABLED_SKUS"].active {
  background: linear-gradient(145deg, #ef4444, #dc2626);
  border-color: #dc2626;
  box-shadow: 0 4px 6px -1px rgba(239, 68, 68, 0.2);
}

/* Active state enhancements */
.sub-type-card.active {
  transform: translateY(-1px);
}

.sub-type-card.active .sub-type-icon {
  background: rgba(255, 255, 255, 0.2);
  color: white;
  transform: scale(1.05);
}

.sub-type-card.active .sub-type-label {
  color: white;
  font-weight: 600;
}

/* Add subtle animation */
@keyframes subtleFloat {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-2px); }
}

.sub-type-card:hover {
  animation: subtleFloat 2s ease-in-out infinite;
}

/* Add focus state for accessibility */
.sub-type-card:focus {
  outline: none;
  ring: 2px solid rgba(99, 102, 241, 0.5);
  ring-offset: 2px;
}

/* Add subtle inner shadow to icon */
.sub-type-icon {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Sold-To-Party Pricing specific styles */
.discount-cell {
  font-family: monospace;
  text-align: right;
  font-weight: 500;
  color: #059669;
}

.date-cell {
  white-space: nowrap;
  color: #4f46e5;
}

.status-badge {
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

.status-active {
  background-color: #dcfce7;
  color: #059669;
}

.status-inactive {
  background-color: #fee2e2;
  color: #dc2626;
}

.table-container {
  margin-top: 1rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.masters-table {
  width: 100%;
  border-collapse: collapse;
}

.masters-table th,
.masters-table td {
  padding: 0.75rem;
  border-bottom: 1px solid #e2e8f0;
}

.masters-table th {
  background: #f8fafc;
  font-weight: 500;
  text-align: left;
  color: #64748b;
}

.masters-table tr:hover {
  background-color: #f8fafc;
}

.action-buttons {
  display: flex;
  gap: 0.25rem;
}

.action-btn {
  padding: 0.25rem;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s;
}

.view-btn:hover { color: #3b82f6; background: #eff6ff; }
.edit-btn:hover { color: #059669; background: #f0fdf4; }
.delete-btn:hover { color: #dc2626; background: #fef2f2; }

/* Add these specific styles for the Sold-To-Party Pricing table */
.numeric-cell {
  font-family: monospace;
  text-align: right;
  padding-right: 1rem !important;
}

.date-cell {
  white-space: nowrap;
}

.upload-section {

  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border-radius: 16px;
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  max-width: 1200px;
  margin: 2rem auto;
}

.upload-info {
  text-align: center;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 
    0 4px 6px -1px rgba(79, 70, 229, 0.1),
    0 2px 4px -1px rgba(79, 70, 229, 0.06);
}

.upload-info h3 {
  color: #1e293b;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem 0;
}

.upload-info p {
  color: #64748b;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.upload-icon {
  color: #4f46e5;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: rgba(79, 70, 229, 0.1);
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.upload-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem 2rem;
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  color: white;
  border: none;
  border-radius: 12px;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 
    0 4px 6px -1px rgba(79, 70, 229, 0.2),
    0 2px 4px -1px rgba(79, 70, 229, 0.1);
}

.upload-btn:hover {
  transform: translateY(-2px);
  box-shadow: 
    0 8px 12px -1px rgba(79, 70, 229, 0.3),
    0 4px 6px -1px rgba(79, 70, 229, 0.2);
  background: linear-gradient(135deg, #4338ca, #3730a3);
}

/* Enhanced Upload Modal Styles */
.upload-modal {
  width: 90%;
  max-width: 1000px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 
    0 25px 50px -12px rgba(0, 0, 0, 0.25),
    0 8px 24px -8px rgba(0, 0, 0, 0.15);
}

.upload-modal .modal-header {
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.upload-modal .modal-header h2 {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.upload-modal .modal-body {
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
  background: #f8fafc;
}

.upload-form {
  background: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
}

/* Master Type Selection Styling */
.form-group label {
  color: #1e293b;
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
  display: block;
}

.form-group select {
  width: 100%;
  padding: 0.875rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  font-size: 0.95rem;
  color: #1e293b;
  background: white;
  transition: all 0.2s;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1.25em;
  padding-right: 2.5rem;
}

.form-group select:hover {
  border-color: #cbd5e1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.form-group select:focus {
  border-color: #4f46e5;
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
  outline: none;
}

/* Enhanced File Upload Area */
.file-upload-wrapper {
  position: relative;
  border: 2px dashed #e2e8f0;
  border-radius: 16px;
  padding: 2.5rem;
  text-align: center;
  transition: all 0.3s ease;
  background: #f8fafc;
  cursor: pointer;
  margin-top: 1.5rem;
  overflow: hidden;
}

.file-upload-wrapper:hover {
  border-color: #4f46e5;
  background: #eef2ff;
  transform: translateY(-2px);
}

.file-upload-wrapper input[type="file"] {
  position: absolute;
  inset: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.file-upload-label {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  pointer-events: none;
}

.file-upload-icon {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(79, 70, 229, 0.1);
  border-radius: 50%;
  color: #4f46e5;
  margin-bottom: 1rem;
}

.file-upload-text {
  font-size: 1.125rem;
  font-weight: 500;
  color: #1e293b;
  margin-bottom: 0.5rem;
}

.file-upload-hint {
  font-size: 0.875rem;
  color: #64748b;
}

.file-types {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  justify-content: center;
}

.file-type-badge {
  padding: 0.25rem 0.75rem;
  background: rgba(79, 70, 229, 0.1);
  color: #4f46e5;
  border-radius: 999px;
  font-size: 0.75rem;
  font-weight: 500;
}

/* File Preview Enhancements */
.file-preview {
  margin-top: 2rem;
  background: white;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  overflow: hidden;
}

.file-info {
  padding: 1rem 1.5rem;
  background: #f8fafc;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.file-preview-name {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: 500;
  color: #1e293b;
}

.file-preview-stats {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  background: #eef2ff;
  color: #4f46e5;
  border-radius: 999px;
  font-size: 0.875rem;
  font-weight: 500;
}

/* Upload Form Improvements */
.upload-form {
  background: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
}

.form-row {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  font-size: 0.875rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 0.75rem;
}

.form-help-text {
  display: block;
  margin-top: 0.5rem;
  color: #64748b;
  font-size: 0.875rem;
}

/* Drag and Drop Animation */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
}

.file-upload-wrapper.dragging {
  animation: pulse 1.5s infinite;
  border-color: #4f46e5;
  background: #eef2ff;
}

/* Upload Progress */
.upload-progress {
  margin-top: 1rem;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 8px;
}

.progress-bar {
  height: 4px;
  background: #e2e8f0;
  border-radius: 2px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #4f46e5, #818cf8);
  transition: width 0.3s ease;
}

.upload-status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #4b5563;
}

/* Preview Table Enhancements */
.preview-table-wrapper {
  max-height: 300px;
  overflow-y: auto;
  margin-top: 1rem;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.preview-table {
  width: 100%;
  border-collapse: collapse;
}

.preview-table th {
  position: sticky;
  top: 0;
  background: #f8fafc;
  padding: 0.75rem 1rem;
  text-align: left;
  font-weight: 600;
  color: #1e293b;
  border-bottom: 2px solid #e2e8f0;
}

.preview-table td {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e2e8f0;
  color: #4b5563;
}

.preview-table tr:last-child td {
  border-bottom: none;
}

.preview-more-rows {
  text-align: center;
  padding: 0.75rem;
  background: #f8fafc;
  color: #64748b;
  font-size: 0.875rem;
  border-top: 1px solid #e2e8f0;
}

/* Modal Footer */
.upload-modal .modal-footer {
  padding: 1.5rem 2rem;
  background: white;
  border-top: 1px solid #e2e8f0;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.upload-modal .modal-btn {
  padding: 0.875rem 1.75rem;
  font-weight: 500;
  border-radius: 10px;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.upload-modal .modal-btn-primary {
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  color: white;
  border: none;
  box-shadow: 0 4px 6px -1px rgba(79, 70, 229, 0.2);
}

.upload-modal .modal-btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 12px -1px rgba(79, 70, 229, 0.3);
}

.upload-modal .modal-btn-primary:disabled {
  background: #e2e8f0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.upload-modal .modal-btn-secondary {
  background: #f1f5f9;
  color: #64748b;
  border: 1px solid #e2e8f0;
}

.upload-modal .modal-btn-secondary:hover {
  background: #e2e8f0;
  color: #1e293b;
}

/* Loading State */
.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .upload-modal {
    width: 95%;
    margin: 1rem;
  }

  .file-upload-wrapper {
    padding: 2rem 1.5rem;
  }

  .upload-modal .modal-btn {
    width: 100%;
    justify-content: center;
  }

  .modal-footer {
    flex-direction: column-reverse;
    gap: 0.75rem;
  }
}

/* Enhanced Upload Form Layout */
.upload-form {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background: white;
  padding: 2.5rem;
  border-radius: 16px;
  box-shadow: 
    0 4px 20px -5px rgba(0, 0, 0, 0.1),
    0 2px 10px -3px rgba(0, 0, 0, 0.05);
}

/* Improved Modal Header */
.upload-modal .modal-header {
  background: white;
  padding: 1.25rem 2rem;
  border-bottom: 1px solid #e2e8f0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
}

.upload-modal .modal-header h2 {
  color: #1e293b;
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

/* Enhanced File Upload Area */
.file-upload-wrapper {
  border: 2px dashed #e2e8f0;
  border-radius: 12px;
  padding: 2.5rem 2rem;
  text-align: center;
  transition: all 0.3s ease;
  background: #f8fafc;
  cursor: pointer;
  margin: 1.5rem 0;
}

/* Compact Data Preview Section */
.excel-preview {
  margin-top: 1.5rem;
}

.excel-preview h4 {
  font-size: 0.875rem;
  color: #64748b;
  font-weight: 600;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.preview-table-wrapper {
  padding: 1rem;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: #f8fafc;
}

.preview-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
}

.preview-table th {
  background: #f1f5f9;
  padding: 0.625rem 0.875rem;
  font-weight: 600;
  color: #475569;
  border-bottom: 2px solid #e2e8f0;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 10;
}

.preview-table td {
  padding: 0.5rem 0.875rem;
  border-bottom: 1px solid #e2e8f0;
  color: #64748b;
}

.preview-more-rows {
  text-align: center;
  padding: 0.75rem;
  color: #64748b;
  background: #f1f5f9;
  border-top: 1px solid #e2e8f0;
  font-size: 0.75rem;
  font-weight: 500;
}

/* Form Group Improvements */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  color: #1e293b;
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  display: block;
}

.form-group select {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.875rem;
  color: #1e293b;
  background: white;
  transition: all 0.2s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* File Info Section Improvements */
.file-info {
  padding: 1rem 1.25rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-top: 1.5rem;
}

.file-preview-name {
  font-size: 0.875rem;
  color: #1e293b;
}

.file-preview-stats {
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
}

/* Close Button Update */
.upload-modal .close-btn {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  background: white;
  color: #64748b;
  transition: all 0.2s;
}

.upload-modal .close-btn:hover {
  background: #f1f5f9;
  color: #1e293b;
  transform: none;
}

/* Enhanced Edit Form Styling */
.edit-modal {
  max-width: 850px;
  width: 95%;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 20px 40px -12px rgba(0, 0, 0, 0.15);
}

.edit-modal .modal-body {
  padding: 24px;
  background: #ffffff;
}

.edit-form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* Detail items for edit modal */
.detail-item {
  margin-bottom: 16px;
  padding: 12px;
  border-radius: 8px;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
}

.detail-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.detail-label {
  font-size: 0.9rem;
  font-weight: 600;
  color: #334155;
}

.detail-value {
  font-size: 0.95rem;
  color: #1e293b;
}

.platform-display {
  padding: 8px 12px;
  background: #eef2ff;
  border-radius: 6px;
  color: #4f46e5;
  font-weight: 500;
  display: inline-block;
}

/* Form field styling */
.form-group {
  margin-bottom: 20px;
  position: relative;
}

.form-group label {
  display: block;
  font-size: 0.9rem;
  font-weight: 600;
  color: #334155;
  margin-bottom: 8px;
}

.required-mark {
  color: #dc2626;
  margin-left: 4px;
  font-weight: bold;
}

/* Input styling */
.form-control {
  width: 100%;
  padding: 12px 14px;
  font-size: 0.95rem;
  color: #1e293b;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.form-control:hover {
  border-color: #cbd5e1;
}

.form-control:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.15);
  background-color: #ffffff;
}

/* Error states */
.form-control.is-invalid {
  border-color: #ef4444;
  background-color: #fef2f2;
}

.error-message {
  color: #ef4444;
  font-size: 0.8rem;
  margin-top: 6px;
  display: flex;
  align-items: center;
}

.help-text {
  display: block;
  margin-top: 6px;
  font-size: 0.8rem;
  color: #64748b;
}

/* Modal footer */
.modal-footer {
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid #e2e8f0;
  background: #f8fafc;
}

.modal-btn {
  padding: 10px 20px;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s;
}

.modal-btn-primary {
  background: linear-gradient(135deg, #4f46e5, #4338ca);
  color: white;
  border: none;
}

.modal-btn-primary:hover {
  background: linear-gradient(135deg, #4338ca, #3730a3);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.2);
}

.modal-btn-secondary {
  background: white;
  color: #1e293b;
  border: 1px solid #cbd5e1;
}

.modal-btn-secondary:hover {
  background: #f8fafc;
  border-color: #94a3b8;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .edit-form-grid {
    grid-template-columns: 1fr;
  }
  
  .edit-modal {
    width: 95%;
    max-width: 100%;
  }
  
  .modal-body {
    padding: 16px;
  }
  
  .form-control {
    padding: 10px 12px;
  }
}

/* Enhanced Edit Form Styling */
.edit-form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
}

.edit-modal .modal-body {
  padding: 24px;
  background: #ffffff;
  display: block; /* Override the existing grid display */
  max-height: 70vh;
  overflow-y: auto;
}

.form-group {
  margin-bottom: 16px;
}

/* For fields that should take full width */
.form-group.full-width {
  grid-column: 1 / -1;
}

/* Ensure proper input spacing */
