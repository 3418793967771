@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.container-fluid {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.card {
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 1rem;
  background-color: #ffffff;
  flex-shrink: 0;
}

.card-body {
  padding: 1rem;
}

.card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #34495e;
  margin-bottom: 0.5rem;
}

.platform-indicator {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  margin-top: 0.5rem;
}

.platform-indicator[data-platform="amazon"] { background-color: #ff9900; }
.platform-indicator[data-platform="flipkart"] { background-color: #0071ce; }
.platform-indicator[data-platform="blinkit"] { background-color: #e53238; }
.platform-indicator[data-platform="bigbasket"] { background-color: #96bf48; }
.platform-indicator[data-platform="zepto"] { background-color: #f1641e; }

.table-container {
  height: calc(100vh - 8rem); /* Increased available space for table */
  min-height: 300px;
  overflow: auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.table-actions {
  display: flex;
  gap: 0.75rem;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table th {
  background: #f8fafc;
  padding: 0.5rem 0.75rem;
  font-weight: 600;
  text-align: left;
  color: #4b5563;
  position: sticky;
  top: 0;
  z-index: 10;
}

.table td {
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid #e5e7eb;
}

.table-hover tbody tr:hover {
  background-color: #f1f5f9;
}

.status-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-approved {
  background-color: #2ecc71;
  color: white;
}

.status-pending {
  background-color: #f39c12;
  color: white;
}
.status-shipped { background-color: #dbeafe; color: #2563eb; }
.status-delivered { background-color: #e0e7ff; color: #4f46e5; }
.status-canceled { background-color: #fee2e2; color: #dc2626; }

.filters-actions-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
}

.filters-actions-row > div {
  flex: 1 1 auto;
  min-width: 150px;
}

.form-select, .form-control {
  border-radius: 0.375rem;
  font-size: 0.875rem;
  border: 1px solid #cbd5e1;
}

.form-select-sm, .form-control-sm, .input-group-sm > .form-control {
  font-size: 0.8125rem;
  padding: 0.25rem 0.5rem;
}

.input-group-text {
  background-color: #f1f5f9;
  border-color: #cbd5e1;
}

.actions-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.875rem;
  transition: all 0.2s;
}

.btn-sm {
  padding: 0.25rem 0.75rem;
  font-size: 0.8125rem;
  height: 2rem;
}

.btn-primary {
  display: none;
  background: #3b82f6;
  color: white;
  border: none;
}

.btn-primary:hover {
  background: #2563eb;
}

.btn-secondary {
  background-color: #64748b;
  border-color: #64748b;
  color: #ffffff;
}

.btn-secondary:hover {
  background-color: #475569;
  border-color: #475569;
}

.btn-success {
  background-color: #10b981;
  border-color: #10b981;
  color: #ffffff;
}

.btn-success:hover {
  background-color: #059669;
  border-color: #059669;
}

.btn-outline-danger {
  color: #dc2626;
  border-color: #dc2626;
}

.btn-outline-danger:hover {
  background-color: #dc2626;
  color: #ffffff;
}

.toggle-btn {
  background: none;
  border: none;
  color: #64748b;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.toggle-btn:hover {
  color: #1e293b;
}

.active-row {
  background-color: #f0f9ff !important;
}

.details-row {
  background: #ffffff !important;
}

.details-content {
  padding: 1.5rem;
  background: #ffffff;
}

.toppled-data {
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow-x: auto;
  margin: 0.5rem;
}

.toppled-data-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #334155;
  margin-bottom: 1rem;
}

.toppled-table {
  width: max-content;
  min-width: 100%;
  font-size: 0.875rem;
  border-collapse: separate;
  border-spacing: 0;
}

.toppled-table th {
  white-space: nowrap;
  padding: 0.75rem 0.5rem;
  background-color: #f1f5f9;
  color: #475569;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  border-bottom: 1px solid #e5e7eb;
}

.toppled-table td {
  white-space: nowrap;
  padding: 0.75rem 0.5rem;
  background-color: #ffffff;
  border-bottom: 1px solid #e5e7eb;
}

.toppled-table-container {
  min-width: 100%;
  overflow-x: auto;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  scroll-behavior: smooth;
  scrollbar-width: auto;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.toppled-table-container::-webkit-scrollbar {
  height: 8px;
}

.toppled-table-container::-webkit-scrollbar-track {
  background: transparent;
}

.toppled-table-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;

  position: relative;
}

.modal-scroll-container {
  max-height: calc(90vh - 120px);
  overflow-y: auto;
  padding: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #374151;
}

.form-control:disabled {
  background-color: #f3f4f6;
  cursor: not-allowed;
}

textarea.form-control {
  resize: vertical;
  min-height: 100px;
}

.checkbox-wrapper {
  position: relative;
  z-index: 1;
}

.form-check-input {
  cursor: pointer;
  width: 1.2rem;
  height: 1.2rem;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.modal-close {
  cursor: pointer;
  color: #6c757d;
  transition: color 0.2s;
}

.modal-close:hover {
  color: #343a40;
}

.modal-body {
  padding: 1.5rem;
}

.edit-modal-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}

.edit-modal-table td {
  padding: 0.75rem;
  background-color: #f9fafb;
  border-radius: 0.25rem;
}

.edit-modal-table .field-name {
  font-weight: 600;
  color: #4b5563;
}

.edit-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

.edit-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container-fluid {
    padding: 0.5rem;
  }

  .card-body {
    padding: 0.75rem;
  }

  .filters-actions-row {
    flex-direction: column;
    align-items: stretch;
  }

  .filters-actions-row > div {
    width: 100%;
  }

  .actions-group {
    justify-content: space-between;
  }

  .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }

  .table td, .table th {
    padding: 0.75rem 0.5rem;
  }
}

.po-table-container {
  height: calc(100vh - 100px);
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-bg);
}

/* Scroll container */
.table-scroll-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 12px;
}

/* Table wrapper - critical for scroll visibility */
.table-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding-bottom: 12px; /* Space for horizontal scrollbar */
}

/* Table responsive container */
.table-responsive {
  flex: 1;
  overflow: auto;
  min-height: 0;
  margin-bottom: 4px; /* Ensure scrollbar visibility */
}

/* Table styles */
.table {
  width: max-content;
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

/* Scrollbar styling */
.table-responsive::-webkit-scrollbar {
  width: 8px;
  height: 12px; /* Increased height for better visibility */
}

.table-responsive::-webkit-scrollbar-track {
  background: #f1f5f9;
  border-radius: 4px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 4px;
  border: 2px solid #f1f5f9;
}

.table-responsive::-webkit-scrollbar-corner {
  background: #f1f5f9;
}

/* Firefox scrollbar */
.table-responsive {
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 #f1f5f9;
}

/* Ensure table header stays fixed */
.table thead th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .po-table-container {
    height: calc(100vh - 90px);
    padding: 12px;
  }

  .table-wrapper {
    padding-bottom: 8px;
  }
}

/* Filter section */
.filters-section {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
}

.filters-content {
  padding: 12px;
}

/* Table wrapper */
.table-wrapper {
  flex: 1;
  min-height: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;
}

.table-scroll {
  flex: 1;
  overflow: auto;
  position: relative;
  padding-bottom: 12px; /* Increased padding for scrollbar visibility */
}

/* Table layout */
.table {
  width: max-content;
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 12px; /* Added margin to ensure scrollbar visibility */
}

/* Scrollbar styling */
.table-scroll::-webkit-scrollbar {
  width: 8px;
  height: 10px; /* Increased height for better visibility */
  display: block !important;
}

.table-scroll::-webkit-scrollbar-track {
  background: #f1f5f9;
  border-radius: 4px;
  margin: 0 8px; /* Added margin for better appearance */
}

.table-scroll::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 4px;
  border: 2px solid #f1f5f9;
}

.table-scroll::-webkit-scrollbar-corner {
  background: #f1f5f9;
}

/* Firefox scrollbar */
.table-scroll {
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 #f1f5f9;
}

/* Ensure bottom space in container */
.po-table-container {
  height: calc(100vh - 88px); /* Adjusted to ensure bottom space */
  padding: 16px 16px 20px 16px; /* Added bottom padding */
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .po-table-container {
    height: calc(100vh - 80px);
    padding: 12px 12px 16px 12px;
  }
  
  .table-scroll::-webkit-scrollbar {
    height: 8px;
  }
}

/* Rest of your existing styles remain the same */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2px; /* Small gap at table bottom */
}

/* Add bottom margin to last row for better spacing */
.table tbody tr:last-child td {
  border-bottom: none;
  padding-bottom: 8px;
}

/* Ensure loading spinner has proper bottom spacing */
.loading-more {
  padding: 8px 0;
  margin-bottom: 4px;
}

/* Remove all unnecessary scroll containers */
.table-scroll-container,
.scrollable-wrapper,
.table-responsive {
  display: contents;
}

/* Single table wrapper */
.table-wrapper {
  flex: 1;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 0.5rem;
  position: relative;
}

/* Single scroll container for table */
.table-scroll {
  flex: 1;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  -webkit-overflow-scrolling: touch;
}

/* Clean scrollbar styling */
.table-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.table-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* Table layout */
.table {
  width: max-content;
  min-width: 100%;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
}

/* Keep header sticky */
.table thead th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .po-table-container {
    padding: 0.5rem;
  }

  .table {
    min-width: 800px; /* For horizontal scrolling on mobile */
  }

  .table-wrapper {
    overflow-x: auto; /* Allow horizontal scroll for mobile */
  }
}

/* Header card with filters */
.filters-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 30;
}

.filters-content {
  padding: 0.5rem;
  background: #ffffff;
  border-radius: 6px;
  width: 100%;
}

.filters-row {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.filter-item {
  flex: 1;
  min-width: 120px; /* Reduced from original size */
}

.input-group {
  display: flex;
  align-items: center;
}

.input-group-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-right: none;
  min-width: 60px;
}

.form-select,
.form-control,
.react-datepicker-wrapper {
  flex: 1;
  width: 200px;
  height: 2rem;
  min-height: 2rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.react-datepicker-wrapper input {
  width: 100% !important;
}

.table td, .table th {
  padding: 0.75rem 0.5rem;
  font-size: 0.875rem;
  text-align: left;
  color: #4b5563;
  border-bottom: 1px solid #e5e7eb;
}

/* Update table layout to accommodate full content */
.table {
  min-width: 100%;
  table-layout: auto;
}

/* Ensure table scroll container handles the horizontal overflow */
.table-scroll {
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Enhanced Input Group Styling */
.input-group {
  display: flex;
  align-items: center;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  transition: all 0.2s;
}

.input-group:focus-within {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.625rem 0.75rem;
  background: transparent;
  border: none;
  color: #6b7280;
}

.form-control {
  border: none;
  padding: 0.625rem 1rem;
  font-size: 0.875rem;
  background: transparent;
  color: #1f2937;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

/* Updated Actions Row */
.actions-row {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 1rem;
  border-top: 1px solid #f1f5f9;
  background: #ffffff;
  border-radius: 0 0 12px 12px;
}

/* Enhanced Button Styling */
.btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 1.25rem;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.875rem;
  transition: all 0.2s;
  border: none;
}

.btn-sm {
  padding: 0.5rem 1rem;
}

/* Updated Button Variants */
.btn-primary {
  background: linear-gradient(to right, #3b82f6, #2563eb);
  color: white;
}

.btn-primary:hover {
  background: linear-gradient(to right, #2563eb, #1d4ed8);
  transform: translateY(-1px);
}

.btn-secondary {
  background-color: #64748b;
  border-color: #64748b;
  color: #ffffff;
}

.btn-secondary:hover {
  background-color: #475569;
  border-color: #475569;
}

.btn-success {
  background: linear-gradient(to right, #10b981, #059669);
  color: white;
}

.btn-success:hover {
  background: linear-gradient(to right, #059669, #0369a1);
  transform: translateY(-1px);
}

.btn-outline-danger {
  color: #dc2626;
  border-color: #dc2626;
}

.btn-outline-danger:hover {
  background-color: #dc2626;
  color: #ffffff;
}

.toggle-btn {
  background: none;
  border: none;
  color: #64748b;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.toggle-btn:hover {
  color: #1e293b;
}

.active-row {
  background-color: #f0f9ff !important;
}

.details-row {
  background: #ffffff !important;
}

.details-content {
  padding: 1.5rem;
  background: #ffffff;
}

.toppled-data {
  padding: 1.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.toppled-data-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #334155;
  margin-bottom: 1rem;
}

.toppled-table {
  font-size: 0.875rem;
}

.toppled-table th {
  background-color: #f1f5f9;
  color: #475569;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
}

.toppled-table td {
  background-color: #ffffff;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;

  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.modal-close {
  cursor: pointer;
  color: #6c757d;
  transition: color 0.2s;
}

.modal-close:hover {
  color: #343a40;
}

.modal-body {
  padding: 1.5rem;
}

.edit-modal-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}

.edit-modal-table td {
  padding: 0.75rem;
  background-color: #f9fafb;
  border-radius: 0.25rem;
}

.edit-modal-table .field-name {
  font-weight: 600;
  color: #4b5563;
}

.edit-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

.edit-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container-fluid {
    padding: 0.5rem;
  }

  .card-body {
    padding: 0.75rem;
  }

  .filters-actions-row {
    flex-direction: column;
    align-items: stretch;
  }

  .filters-actions-row > div {
    width: 100%;
  }

  .actions-group {
    justify-content: space-between;
  }

  .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }

  .table td, .table th {
    padding: 0.75rem 0.5rem;
  }
}

.po-table-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  background-color: #f0f4f8;
}

/* Header card with filters */
.filters-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 30;
}

.filters-content {
  padding: 0.5rem 1rem;
}

.filters-row {
  display: flex;
  gap: 12px;
  align-items: center;
}

.filter-item {
  flex: 0 0 auto;
}

.input-group {
  display: flex;
  align-items: center;
}

.input-group-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-right: none;
  min-width: 60px;
}

.form-select,
.form-control,
.react-datepicker-wrapper {
  flex: 1;
  width: 200px;
  height: 2rem;
  min-height: 2rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.react-datepicker-wrapper input {
  width: 100% !important;
}

.actions-row {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-top: 1px solid #e5e7eb;
  background: #f8fafc;
}

/* Table container */
.table-wrapper {
  flex: 1;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.table-scroll {
  overflow: auto;
  flex: 1;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.table thead {
  position: sticky;
  top: 0;
  z-index: 20;
  background: white;
}

.table th, .table td {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  text-align: left;
  color: #4b5563;
  border-bottom: 1px solid #e5e7eb;
}

.table th {
  background: #f8fafc;
  font-weight: 600;
}

.table td {
  font-size: 0.875rem;
}

.table-hover tbody tr:hover {
  background-color: #e2e8f0;
}

.status-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
}

.platform-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  padding: 0.5rem 1rem;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;
}

/* Cool background pattern */
.platform-tabs::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(
    to right,
    var(--platform-color-1),
    var(--platform-color-2),
    var(--platform-color-3),
    var(--platform-color-4),
    var(--platform-color-5)
  );
}

/* Define color palette */
:root {
  --platform-color-1: #ff9900;
  --platform-color-2: #0071ce;
  --platform-color-3: #e53238;
  --platform-color-4: #96bf48;
  --platform-color-5: #f1641e;
  --platform-color-6: #4a154b;
  --platform-color-7: #00b1e1;
  --platform-color-8: #6cc644;
  --platform-color-9: #e4405f;
  --platform-color-10: #1da1f2;
}

/* Base platform tab styling */
.platform-tab {
  position: relative;
  padding: 0.5rem 1rem;
  text-align: center;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid #e5e7eb;
  color: #64748b;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  height: 2.25rem;
  user-select: none;
}

/* Platform indicator dot */
.platform-tab {
  position: relative;
  padding: 0.5rem 1rem;
  text-align: center;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.2s ease;
  border: 1px solid #e5e7eb;
  color: #64748b;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 2.25rem;
  min-width: 120px;
  text-transform: none;
}

/* Platform indicator dot */
.platform-tab::before {
  content: '';
  position: absolute;
  left: 0.75rem;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.2s ease;
}

/* Dynamic color assignments */
.platform-tab:nth-of-type(10n + 1)::before { background-color: var(--platform-color-1); }
.platform-tab:nth-of-type(10n + 2)::before { background-color: var(--platform-color-2); }
.platform-tab:nth-of-type(10n + 3)::before { background-color: var(--platform-color-3); }
.platform-tab:nth-of-type(10n + 4)::before { background-color: var(--platform-color-4); }
.platform-tab:nth-of-type(10n + 5)::before { background-color: var(--platform-color-5); }
.platform-tab:nth-of-type(10n + 6)::before { background-color: var(--platform-color-6); }
.platform-tab:nth-of-type(10n + 7)::before { background-color: var(--platform-color-7); }
.platform-tab:nth-of-type(10n + 8)::before { background-color: var(--platform-color-8); }
.platform-tab:nth-of-type(10n + 9)::before { background-color: var(--platform-color-9); }
.platform-tab:nth-of-type(10n + 10)::before { background-color: var(--platform-color-10); }

/* Platform name */
.platform-tab .platform-name {
  flex: 1;
  text-align: center;
  transition: transform 0.2s ease;
}

/* Badge styling */
.platform-tab .badge {
  padding: 0.25rem 0.5rem;
  border-radius: 999px;
  font-size: 0.75rem;
  font-weight: 600;
  background: #f1f5f9;
  color: #64748b;
  transition: all 0.2s ease;
}

/* Hover effects */
.platform-tab:hover:not(.active) {
  background: #f8fafc;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.platform-tab:hover::before {
  opacity: 1;
}

/* Active state */
.platform-tab.active {
  background: #f8fafc;
  border-color: #cbd5e1;
  color: #1e293b;
  font-weight: 600;
  padding-left: 1.75rem;
}

.platform-tab.active::before {
  opacity: 1;
}

.platform-tab.active .badge {
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Loading animation for tabs */
@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

.platform-tab.loading {
  animation: pulse 1.5s infinite;
  pointer-events: none;
}

/* Cool hover effect for the count */
.platform-tab:hover .badge {
  transform: scale(1.05);
}

/* Responsive design */
@media (max-width: 768px) {
  .platform-tabs {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    padding: 0.75rem;
    gap: 0.5rem;
  }

  .platform-tab {
    padding: 0.5rem 1rem;
    height: 2.5rem;
    font-size: 0.8125rem;
  }
}

/* Optional: Add a subtle animation when tabs are loaded */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.platform-tab {
  animation: fadeInUp 0.3s ease forwards;
}

.platform-tab:nth-child(2) { animation-delay: 0.1s; }
.platform-tab:nth-child(3) { animation-delay: 0.2s; }
.platform-tab:nth-child(4) { animation-delay: 0.3s; }
.platform-tab:nth-child(5) { animation-delay: 0.4s; }

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.loading-spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Enhanced action buttons */
.action-buttons {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  padding: 0.5rem;
}

.action-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.875rem;
  transition: all 0.2s;
  border: none;
  background: #f8fafc;
  color: #475569;
  cursor: pointer;
}

.action-btn:hover {
  background: #f1f5f9;
  transform: translateY(-1px);
}

.action-btn i {
  font-size: 1.1rem;
}

.po-table-container {
  width: 100%;
  max-width: 100%;
  padding: 1rem;
  margin: 0 auto;
}

.platform-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.platform-tab {
  flex: 1;
  min-width: 100px;
  padding: 0.5rem;
  white-space: nowrap;
}

.filters-card {
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.filters-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.filter-item {
  flex: 1;
  min-width: 200px;
}

.actions-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.table-wrapper {
  overflow-x: auto;
  max-width: 100%;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .po-table-container {
    padding: 0.5rem;
    overflow-x: hidden;
  }

  /* Platform tabs adjustments */
  .platform-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 0.5rem;
    margin: 0 -0.5rem;
    padding: 0 0.5rem;
  }

  .platform-tab {
    flex: 0 0 auto;
    min-width: 120px;
    font-size: 0.875rem;
    padding: 0.5rem 0.75rem;
  }

  /* Filters adjustments */
  .filters-card {
    padding: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .filters-row {
    flex-direction: column;
    gap: 0.75rem;
  }

  .filter-item {
    width: 100%;
    min-width: unset;
  }

  /* Action buttons adjustments */
  .actions-row {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .action-btn {
    flex: 1 1 calc(50% - 0.25rem);
    min-width: unset;
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  /* Table adjustments */
  .table-wrapper {
    margin: 0 -0.5rem;
    width: calc(100% + 1rem);
  }

  .table {
    font-size: 0.875rem;
  }

  .table th,
  .table td {
    padding: 0.5rem;
    min-width: 100px;
  }

  /* Make specific columns smaller */
  .table th:first-child,
  .table td:first-child,
  .table th:nth-child(2),
  .table td:nth-child(2) {
    padding: 0.5rem;
    width: 40px;
    min-width: 40px;
  }

  /* Status badge adjustments */
  .status-badge {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }

  /* Modal adjustments */
  .modal-content {
    width: 95%;
    margin: 1rem auto;
    max-height: 90vh;
    overflow-y: auto;
  }

  .modal-body {
    padding: 1rem;
  }

  /* Edit form adjustments */
  .edit-modal-table {
    font-size: 0.875rem;
  }

  .edit-input {
    width: 100%;
    padding: 0.5rem;
  }

  /* Loading overlay adjustment */
  .loading-overlay {
    position: absolute;
  }
}

/* Add these new utility classes */
.table-responsive-wrapper {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.mobile-scroll-indicator {
  display: none;
  text-align: center;
  color: #666;
  font-size: 0.75rem;
  padding: 0.5rem;
}

@media (max-width: 768px) {
  .mobile-scroll-indicator {
    display: block;
  }
}

/* Platform tabs styling */
.platform-tabs-container {
  margin-bottom: 1rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.platform-tabs {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  border-bottom: 1px solid #e5e7eb;
}

.platform-tab {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  color: #64748b;
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;
  background: transparent;
  border: none;
}

.platform-tab.active {
  background-color: #f1f5f9;
  color: #0f172a;
}

/* Mobile specific adjustments */
@media (max-width: 768px) {
  .po-table-container {
    padding: 0.5rem;
  }

  /* Platform tabs mobile styling */
  .platform-tabs-container {
    margin: -0.5rem -0.5rem 0.5rem -0.5rem;
    border-radius: 0;
  }

  .platform-tabs {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    padding: 0.5rem;
    gap: 0.25rem;
  }

  .platform-tabs::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  .platform-tab {
    flex: 0 0 auto;
    padding: 0.5rem 0.75rem;
    font-size: 0.813rem;
    min-width: auto;
  }

  /* Filters card mobile styling */
  .filters-card {
    margin: 0 -0.5rem 0.5rem -0.5rem;
    border-radius: 0;
    background: white;
  }

  .filters-content {
    padding: 0.75rem;
  }

  .filters-row {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .filter-item {
    width: 100%;
  }

  /* Form controls mobile styling */
  .form-select,
  .form-control,
  .react-datepicker-wrapper {
    width: 100%;
    height: 40px;
  }

  .form-control {
    padding: 0.5rem 0.75rem;
  }

  /* Actions row mobile styling */
  .actions-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    padding: 0.75rem;
    background: #f8fafc;
  }

  .btn {
    width: 100%;
    height: 36px;
    padding: 0 0.75rem;
    font-size: 0.813rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.375rem;
  }

  /* Table mobile styling */
  .table-container {
    margin: 0 -0.5rem;
    border-radius: 0;
  }

  .table-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table {
    min-width: 800px; /* Ensure minimum width for scrolling */
  }

  .table th,
  .table td {
    padding: 0.75rem 0.5rem;
    font-size: 0.813rem;
  }

  /* Status badge mobile styling */
  .status-badge {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }
}

/* Update the main container */
.po-table-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #f0f4f8;
  max-width: 100%;
  margin: 0 auto;
}

/* Platform tabs and filters should not flex or grow */
.platform-tabs,
.filters-card {
  flex: 0 0 auto; /* Don't grow or shrink */
}

/* Table wrapper should take remaining space */
.table-wrapper {
  flex: 1 1 auto;
  min-height: 0; /* Important for Firefox */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 0.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Table scroll container */
.table-scroll {
  flex: 1 1 auto;
  overflow: auto;
  min-height: 0; /* Important for Firefox */
}

/* Keep header fixed */
.table thead {
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .po-table-container {
    padding: 0.5rem;
    height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  }
}

/* Add horizontal scroll */
.table-responsive {
  overflow-x: auto;
  margin-bottom: 1rem;
}

.table-scroll {
  min-width: 100%;
}

/* Ensure table takes full width */
.table {
  min-width: 1200px; /* Adjust based on your needs */
  width: 100%;
}

/* Style the pagination */
.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: white;
  border-top: 1px solid #e5e7eb;
}

.pagination-info {
  color: #6b7280;
  font-size: 0.875rem;
}

.pagination-controls {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.pagination-button {
  padding: 6px 12px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}

.pagination-button:hover:not(:disabled) {
  background-color: #f5f5f5;
}

.pagination-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.pagination-button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.page-size-selector {
  display: flex;
  align-items: center;
}

.page-size-selector select {
  margin: 0 5px;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
/* Sticky header */
.table thead th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}

/* Table wrapper with both scrolls */
.table-wrapper {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .pagination-container {
    flex-direction: column;
    gap: 1rem;
  }

  .pagination-controls {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
}

/* Loading state */
.table-wrapper.loading {
  opacity: 0.7;
  pointer-events: none;
}

/* Optional: Add smooth scrolling */
.table-scroll-container {
  scroll-behavior: smooth;
}

/* Table cell single line styling */
.table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust this value based on your needs */
}

/* Ensure table header cells also stay in single line */
.table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust this value based on your needs */
}

/* Update table wrapper to handle horizontal scroll */
.table-scroll {
  overflow-x: auto;
  overflow-y: auto;
}

/* Ensure minimum width for the table */
.table {
  min-width: 100%;
  table-layout: fixed; /* This helps maintain consistent column widths */
}

/* Update table wrapper to ensure horizontal scrollbar is visible */
.table-scroll {
  overflow-x: auto;
  overflow-y: auto;
  margin-bottom: 0.5rem; /* Add space for the scrollbar */
  padding-bottom: 0.5rem;
}

/* Optimize toggle button (down arrow) styling */
.toggle-btn {
  padding: 0 !important;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
}

/* Ensure table headers and cells show full content */
.table th, 
.table td {
  white-space: nowrap;
  overflow: visible;
  text-overflow: clip;
  padding: 0.75rem 0.5rem;
  min-width: auto;
  max-width: none;
}

/* Update table layout */
.table {
  width: max-content;
  min-width: 100%;
  table-layout: auto;
}

/* Ensure the table container allows horizontal scroll */
.table-responsive {
  overflow-x: auto;
  margin-bottom: 1rem;
  /* Add smooth scrolling for better UX */
  scroll-behavior: smooth;
  /* Show scrollbar on supported browsers */
  scrollbar-width: auto;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

/* Style scrollbar for WebKit browsers */
.table-responsive::-webkit-scrollbar {
  height: 8px;
}

.table-responsive::-webkit-scrollbar-track {
  background: transparent;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 4px;
}

/* Update gradient overlays */
.table-scroll::before,
.table-scroll::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 48px;
  pointer-events: none;
  z-index: 10;
}

.table-scroll.show-left-gradient::before {
  left: 0;
  background: linear-gradient(to right, 
    rgba(255, 255, 255, 0.95) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    transparent 100%);
}

.table-scroll.show-right-gradient::after {
  right: 0;
  background: linear-gradient(to left, 
    rgba(255, 255, 255, 0.95) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    transparent 100%);
}

/* Ensure table wrapper has proper positioning */
.table-wrapper {
  position: relative;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.scroll-arrow {
  display: none; /* This will hide the arrows */
}

.table-responsive {
  padding: 0; /* Remove the padding that was making space for arrows */
  margin: 0; /* Remove the negative margin */
  overflow-x: auto; /* Ensure horizontal scrolling still works */
}

.sidebar-nav {
  flex: 1;
  overflow-y: auto;
  padding: 0.75rem 0.5rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin: 0.125rem 0;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.2s ease;
  will-change: transform;
  transform: translateZ(0);
}

/* Modern Filter Styles */
.filters-content {
  padding: 0.75rem;
  background: #ffffff;
  border-radius: 6px;
  width: 100%;
}

.filters-row {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
}

.filter-item {
  flex: 1;
  width: calc(33.333% - 0.667rem); /* Distribute evenly, accounting for gaps */
  min-width: 0; /* Allow shrinking below min-width */
}

.custom-filter-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.filter-label {
  font-size: 0.75rem;
  font-weight: 500;
  color: #64748b;
  margin-left: 0.25rem;
}

.custom-select,
.custom-input,
.date-picker {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  height: 32px; /* Reduced height */
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background: white;
  width: 100%;
}

.custom-select {
  padding-right: 2rem;
  background-size: 0.875rem;
  background-position: right 0.5rem center;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.search-icon {
  position: absolute;
  left: 0.5rem;
  color: #64748b;
  width: 12px;
  height: 12px;
}

.custom-input {
  padding-left: 2rem;
}

/* DatePicker wrapper styles */
.react-datepicker-wrapper {
  width: 100% !important;
}

.date-picker-wrapper {
  position: relative;
  width: 100%;
  z-index: 1000;
}

/* Ensure date picker input has consistent styling */
.date-picker-wrapper .react-datepicker-wrapper {
  width: 100%;
}

.date-picker-wrapper input.form-control {
  width: 100%;
  height: 36px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

/* Fix date picker popup positioning and styling */
.react-datepicker-popper {
  z-index: 10000 !important;
}

.react-datepicker {
  font-family: inherit;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #ced4da;
  padding: 8px;
}

.react-datepicker__day--selected {
  background-color: #007bff;
  color: white;
}

.react-datepicker__day:hover {
  background-color: #e9ecef;
}

/* Ensure modal content doesn't interfere with date picker */
.modal-content.process-modal {
  position: relative;
  z-index: 1000;
}

/* Add specific styling for export modal date pickers */
.process-modal .date-picker-wrapper {
  margin-bottom: 1rem;
}

.process-modal .form-group {
  position: relative;
}

/* Ensure date picker portal renders correctly */
#root .react-datepicker-popper {
  transform: none !important;
  position: fixed !important;
  top: auto !important;
  left: auto !important;
}

/* Add responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .date-picker-wrapper {
    width: 100%;
  }
  
  .react-datepicker-popper {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    max-width: 90vw;
  }
  
  .react-datepicker {
    width: 100%;
  }
}

/* Export Data Modal specific styles */
.export-modal {
  position: relative;
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 800px; /* Increased to accommodate inline calendars */
  z-index: 1050;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
}

.modal-body {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.form-group {
  flex: 1;
}

.date-picker-container {
  width: 100%;
}

.react-datepicker {
  width: 100%;
  font-family: inherit;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.react-datepicker__month-container {
  float: none;
  width: 100%;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}