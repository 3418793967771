/* DSA Revamp Main Container */
.dsa-revamp-container {
  height: 100%;
  background: linear-gradient(135deg, rgba(247, 249, 255, 0.9), rgba(255, 255, 255, 0.9));
  overflow: auto;
}

.dsa-revamp-container.ant-layout {
  min-height: calc(100vh - 64px);
  overflow: auto;
}

/* Glass Effect */
.glass-effect {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.07);
  border-radius: 12px !important;
}

/* Sidebar Styles */
.dsa-sidebar {
  position: relative;
  height: 100%;
  margin: 16px 0 16px 16px;
  border-radius: 12px !important;
  overflow: hidden;
}

.dsa-sidebar-header {
  padding: 16px;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.dsa-sidebar-header h5 {
  color: #1a1a1a;
  margin: 0;
}

.dsa-menu {
  background: transparent !important;
  border: none !important;
  padding: 8px;
}

.dsa-menu .ant-menu-item {
  border-radius: 8px;
  margin: 4px 0 !important;
  height: 48px !important;
  line-height: 48px !important;
}

.dsa-menu .ant-menu-item:hover {
  background: rgba(24, 144, 255, 0.1) !important;
}

.dsa-menu .ant-menu-item-selected {
  background: #1890ff !important;
}

.dsa-menu .ant-menu-item-selected span,
.dsa-menu .ant-menu-item-selected .anticon {
  color: white !important;
}

/* Main Layout */
.dsa-main-layout {
  padding: 16px 16px 16px 0;
  background: transparent;
}

/* Header Styles */
.dsa-header {
  padding: 0 24px;
  height: 64px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.dsa-header h4.ant-typography {
  color: #1a1a1a;
  font-weight: 600;
  letter-spacing: -0.5px;
  margin: 0;
  flex: 1;
}

.dsa-header .trigger {
  font-size: 20px;
  cursor: pointer;
  transition: color 0.3s;
  color: #1a1a1a;
}

.dsa-header .trigger:hover {
  color: #1890ff;
}

/* Content Area */
.dsa-content {
  min-height: 500px;
  padding: 20px;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 64px);
}

/* View Container */
.view-container {
  padding: 20px;
  border-radius: 8px;
  min-height: 400px;
  overflow-y: auto;
}

/* PowerBI container */
.powerbi-container {
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 180px);
  border-radius: 12px;
  overflow: hidden;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .dsa-revamp-container {
    flex-direction: column;
  }

  .dsa-sidebar {
    margin: 16px;
    width: calc(100% - 32px) !important;
    flex: none !important;
  }

  .dsa-main-layout {
    padding: 0 16px 16px;
  }

  .dsa-header {
    padding: 0 16px;
    height: 56px;
  }

  .view-container {
    padding: 16px;
  }

  .powerbi-container {
    min-height: calc(100vh - 160px);
  }
}

/* Card and Component Styles */
.ant-card {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  height: 100%;
}

.ant-card-head {
  border-bottom: 1px solid #f0f0f0;
  padding: 12px 16px;
}

.ant-card-body {
  padding: 16px;
}

/* Card Styles */
.metric-card,
.chart-card,
.table-card,
.alerts-card {
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.metric-card:hover,
.chart-card:hover,
.alerts-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1) !important;
}

.metric-card .ant-card-head,
.chart-card .ant-card-head,
.table-card .ant-card-head,
.alerts-card .ant-card-head {
  background: transparent;
  border-bottom: 1px solid rgba(240, 240, 240, 0.6);
  padding: 16px 24px;
}

.card-icon {
  color: #1890ff;
  font-size: 20px;
}

.metric-card .ant-card-head-title,
.chart-card .ant-card-head-title,
.table-card .ant-card-head-title,
.alerts-card .ant-card-head-title {
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
}

.metric-card .ant-card-body,
.chart-card .ant-card-body,
.table-card .ant-card-body,
.alerts-card .ant-card-body {
  padding: 24px;
}

/* Metric Container */
.metric-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.metric {
  text-align: center;
}

.metric-value {
  font-size: 24px;
  font-weight: 600;
  margin: 8px 0;
}

.metric-value.success {
  color: #52c41a;
}

.metric-value.warning {
  color: #faad14;
}

.metric-value.danger {
  color: #f5222d;
}

.metric-label {
  color: #8c8c8c;
  font-size: 14px;
}

/* Chart placeholders */
.chart-content, .table-content {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8c8c8c;
}

.chart-placeholder, .table-placeholder {
  font-size: 16px;
}

/* Alert styles */
.alerts-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.alert-item {
  padding: 16px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(24, 144, 255, 0.1);
  margin-bottom: 12px;
  transition: all 0.3s ease;
}

.alert-item:hover {
  background: rgba(255, 255, 255, 0.95);
  border-color: rgba(24, 144, 255, 0.2);
  transform: translateX(4px);
}

.alert-item:last-child {
  margin-bottom: 0;
}

.alert-content h5 {
  margin-bottom: 8px;
  color: #1a1a1a;
}

.alert-content .ant-typography {
  color: #595959;
}

/* Spacing utility */
.mt-4 {
  margin-top: 24px;
}

.mb-4 {
  margin-bottom: 24px;
}

/* Progress and Chart Styles */
.ant-progress-text {
  color: #1a1a1a !important;
  font-weight: 500;
}

.chart-content {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
}

.chart-placeholder {
  color: #8c8c8c;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.dashboard-view {
  padding: 20px;
  border-radius: 8px;
  min-height: 400px;
  overflow-y: auto;
}

.dashboard-header {
  margin-bottom: 16px;
}

.dashboard-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Metric Cards */
.metric-card {
  height: 100%;
  transition: all 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
}

.metric-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.metric-card .ant-statistic-title {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}

.metric-card .ant-statistic-content {
  font-size: 28px;
  font-weight: 600;
}

.metric-footer {
  margin-top: 12px;
  font-size: 12px;
}

/* Chart Cards */
.chart-card {
  height: 400px;
  margin-bottom: 16px;
  border-radius: 12px;
  overflow: hidden;
}

.chart-card .ant-card-head {
  padding: 12px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.chart-content {
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.45);
}

.chart-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 16px;
}

.chart-description {
  margin-top: 16px;
  text-align: center;
  max-width: 80%;
}

/* Insights Card */
.insights-card {
  margin-bottom: 16px;
  border-radius: 12px;
  overflow: hidden;
}

.insight-item {
  height: 100%;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.insight-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.insight-change {
  margin-top: 8px;
  font-size: 12px;
}

/* Table Card */
.table-card {
  margin-bottom: 16px;
  border-radius: 12px;
  overflow: hidden;
}

.table-card .ant-table-thead > tr > th {
  background-color: rgba(0, 0, 0, 0.02);
  font-weight: 600;
}

.table-card .ant-table-tbody > tr:hover > td {
  background-color: rgba(24, 144, 255, 0.05);
}

/* Alerts Card */
.alerts-card {
  margin-bottom: 16px;
  border-radius: 12px;
  overflow: hidden;
}

.alerts-list {
  max-height: 400px;
  overflow-y: auto;
}

.alert-item {
  padding: 16px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 12px;
  transition: all 0.3s ease;
}

.alert-item:hover {
  background: rgba(255, 255, 255, 0.95);
  border-color: rgba(24, 144, 255, 0.2);
  transform: translateX(4px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}

.alert-item:last-child {
  margin-bottom: 0;
}

.alert-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.alert-description {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.65);
}

.alert-actions {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
}

/* Card Icons */
.card-icon {
  color: #1890ff;
  font-size: 18px;
  margin-right: 4px;
}

/* Utility Classes */
.mt-4 {
  margin-top: 24px;
}

.mb-4 {
  margin-bottom: 24px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .dashboard-actions {
    justify-content: flex-start;
    margin-top: 12px;
    flex-wrap: wrap;
  }
  
  .dashboard-actions .ant-space {
    flex-wrap: wrap;
  }
  
  .chart-card {
    height: 350px;
  }
  
  .chart-content {
    height: 270px;
  }
  
  .alert-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .alert-header > .ant-space:last-child {
    margin-top: 8px;
  }
}