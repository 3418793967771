/* Client/src/components/Admin/AdminDashboard.css */

/* Main Layout */
.admin-dashboard-layout {
  min-height: 100vh;
  background: linear-gradient(135deg, #f6f8fc, #eef2f9);
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.admin-dashboard-content {
  flex: 1;
  padding: 24px;
  overflow: auto;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Header Section */
.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  background: white;
  padding: 22px 28px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(31, 38, 135, 0.07);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.admin-title-section {
  display: flex;
  flex-direction: column;
}

.admin-title {
  margin: 0 !important;
  color: #1e293b;
  font-weight: 700 !important;
  letter-spacing: -0.5px;
  font-size: 28px !important;
  background: linear-gradient(90deg, #1e40af, #3b82f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.admin-subtitle {
  margin-top: 6px;
  font-size: 15px;
  color: #64748b;
}

.admin-controls {
  display: flex;
  align-items: center;
  gap: 12px;
}

.search-input {
  width: 280px;
  border-radius: 8px;
  transition: all 0.3s;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border: 1px solid #e2e8f0;
  padding: 8px 12px;
  height: 42px;
}

.search-input:hover, .search-input:focus {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-color: #bfdbfe;
}

.search-icon {
  color: #64748b;
}

.add-user-button {
  background: linear-gradient(135deg, #3b82f6, #1e40af);
  border: none;
  border-radius: 8px;
  transition: all 0.3s;
  height: 42px;
  padding: 0 20px;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

.add-user-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(59, 130, 246, 0.4);
}

.refresh-button {
  border-radius: 8px;
  background: #fff;
  color: #3b82f6;
  border: 1px solid #e2e8f0;
  transition: all 0.3s;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.refresh-button:hover {
  background: #f1f5f9;
  transform: rotate(180deg);
  color: #1e40af;
  border-color: #bfdbfe;
}

/* User Tabs */
.user-tabs-container {
  margin-bottom: 24px;
}

.user-tabs {
  background: white;
  padding: 8px 12px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(31, 38, 135, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.user-tabs .ant-tabs-tab {
  padding: 10px 16px;
  margin: 0 4px;
  transition: all 0.3s;
  border-radius: 8px;
}

.user-tabs .ant-tabs-tab:hover {
  background-color: #f1f5f9;
}

.user-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #eff6ff;
}

.user-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #3b82f6;
  font-weight: 500;
}

.user-tabs .ant-badge-count {
  background: #3b82f6;
}

/* Table Section */
.user-table-container {
  overflow: auto;
  margin-bottom: 24px;
}

.users-table .ant-table {
  background: transparent;
}

.users-table .ant-table-thead > tr > th {
  background-color: #f8fafc;
  font-weight: 600;
  color: #334155;
  padding: 16px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.users-table .ant-table-tbody > tr > td {
  padding: 16px;
  transition: all 0.2s;
}

.users-table .ant-table-tbody > tr:hover > td {
  background-color: #f1f5f9;
}

.user-cell {
  display: flex;
  align-items: center;
}

.user-avatar {
  margin-right: 16px;
  width: 42px;
  height: 42px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-info {
  display: flex;
  flex-direction: column;
}

.username {
  font-weight: 600;
  color: #1e293b;
  font-size: 15px;
}

.user-email {
  font-size: 13px;
  color: #64748b;
  margin-top: 3px;
}

/* Admin Row Styling */
.admin-row {
  background-color: #fef2f2;
}

.admin-row:hover > td {
  background-color: #fee2e2 !important;
}

/* Action Buttons */
.action-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.edit-button {
  background-color: #1890ff;
  border-color: #1890ff;
  transition: all 0.3s;
}

.edit-button:hover {
  background-color: #40a9ff;
  border-color: #40a9ff;
  transform: scale(1.05);
  box-shadow: 0 2px 8px rgba(24, 144, 255, 0.2);
}

.delete-button {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  transition: all 0.3s;
}

.delete-button:hover {
  background-color: #ff7875;
  border-color: #ff7875;
  transform: scale(1.05);
  box-shadow: 0 2px 8px rgba(255, 77, 79, 0.2);
}

/* Tags and Badges */
.ant-tag {
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 500;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.ant-badge-status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.ant-badge-status-text {
  font-size: 14px;
  font-weight: 500;
}

/* Expandable Row Content */
.expanded-row-content {
  padding: 20px;
  background-color: #f8fafc;
  border-radius: 8px;
  margin: 0 16px 16px;
  box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  max-height: 300px;
}

.permission-summary {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.permission-section {
  flex: 1;
  min-width: 200px;
}

.permission-section h4 {
  margin-bottom: 16px;
  font-weight: 600;
  color: #334155;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
}

.permission-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.permission-tags .ant-tag {
  transition: all 0.2s;
  margin: 0;
}

.permission-tags .ant-tag:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Drawer Styling */
.user-drawer .ant-drawer-header {
  padding: 20px 24px;
  background: linear-gradient(to right, #eff6ff, #f8fafc);
  border-bottom: 1px solid #e2e8f0;
}

.drawer-title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
}

.drawer-header-with-avatar {
  display: flex;
  align-items: center;
  width: 100%;
}

.drawer-title-text {
  display: flex;
  flex-direction: column;
}

.drawer-subtitle {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 4px;
}

.drawer-icon {
  margin-right: 8px;
  color: #1890ff;
}

.user-profile-header {
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px dashed #f0f0f0;
}

.user-profile-status {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.last-login-info {
  margin-top: 4px;
}

.user-form {
  padding: 12px 8px;
}

.form-section {
  background-color: #fafafa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 24px;
  transition: all 0.3s;
}

.form-section:hover {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  background-color: #fff;
}

.section-header {
  margin-bottom: 16px;
}

.section-title {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 4px 0;
  display: flex;
  align-items: center;
}

.section-icon {
  margin-right: 8px;
  color: #1890ff;
}

/* Form Elements */
.ant-form-item-label > label {
  font-weight: 500;
  color: #334155;
}

.ant-input {
  border-radius: 8px;
  padding: 10px 12px;
  border-color: #e2e8f0;
  transition: all 0.3s;
}

.ant-input:hover, .ant-input:focus {
  border-color: #bfdbfe;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #bfdbfe !important;
}

.ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
  border-color: #bfdbfe !important;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1) !important;
}

.ant-select-selector {
  border-radius: 8px !important;
  padding: 5px 12px !important;
  border-color: #e2e8f0 !important;
  height: 42px !important;
  transition: all 0.3s !important;
}

.ant-select-selection-item {
  line-height: 32px !important;
}

/* Switch Styling */
.ant-switch {
  min-width: 160px;
  height: 30px;
  border-radius: 30px;
  background-color: #e2e8f0;
}

.ant-switch-handle {
  width: 24px;
  height: 24px;
  top: 3px;
  left: 3px;
}

.ant-switch-handle::before {
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.ant-switch-checked {
  background-color: #3b82f6;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 28px);
}

.ant-switch-checked .ant-switch-inner {
  padding-right: 30px;
  padding-left: 10px;
}

.ant-switch-inner {
  padding-left: 30px;
  padding-right: 10px;
  font-weight: 500;
  font-size: 13px;
}

.module-switch.ant-switch-checked {
  background-color: #10b981;
}

.platform-switch.ant-switch-checked {
  background-color: #8b5cf6;
}

.permission-switches .ant-col {
  margin-bottom: 12px;
}

/* Button Styling */
.cancel-button {
  border-radius: 8px;
  padding: 0 20px;
  height: 40px;
  font-weight: 500;
  border-color: #e2e8f0;
  color: #64748b;
}

.save-button {
  border-radius: 8px;
  padding: 0 20px;
  height: 40px;
  font-weight: 500;
  background: linear-gradient(135deg, #3b82f6, #1e40af);
  border: none;
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

.save-button:hover {
  background: linear-gradient(135deg, #2563eb, #1e3a8a);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(59, 130, 246, 0.4);
}

/* Empty State */
.ant-empty {
  margin: 48px 0;
}

.ant-empty-description {
  color: #64748b;
  font-size: 16px;
  margin-top: 16px;
}

/* Additional styles to add to the CSS file */
.header-icon {
  margin-right: 10px;
  font-size: 24px;
}

.status-badge {
  margin-left: auto;
}

.inactive-row {
  opacity: 0.7;
}

.table-row {
  position: relative;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.table-row:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.06);
  z-index: 1;
}

.expand-icon {
  background: #f8fafc;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s;
  color: #64748b;
}

.expand-icon:hover {
  background: #e0f2fe;
  color: #0284c7;
  transform: scale(1.1);
}

.expand-icon.expanded {
  background: #3b82f6;
  color: white;
}

.custom-pagination {
  margin-top: 16px;
}

.switch-item {
  margin-bottom: 8px !important;
}

.module-switch, .platform-switch {
  width: 100%;
  transition: all 0.3s;
}

.module-switch:hover, .platform-switch:hover {
  opacity: 0.9;
}

/* Enhanced tab styling */
.tab-label {
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.tab-icon {
  margin-right: 8px;
  font-size: 16px;
}

.tab-badge {
  transition: all 0.3s;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .admin-dashboard-content {
    padding: 16px;
  }
  
  .admin-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
  }
  
  .admin-controls {
    margin-top: 16px;
    width: 100%;
    justify-content: space-between;
  }
  
  .search-input {
    width: 100%;
    margin-top: 8px;
  }
  
  .user-tabs .ant-tabs-nav {
    margin-bottom: 16px;
  }
  
  .tab-label {
    flex-direction: column;
    text-align: center;
    padding: 8px 12px;
  }
  
  .tab-icon {
    margin-right: 0;
    margin-bottom: 4px;
    font-size: 18px;
  }
  
  .tab-badge {
    margin-top: 4px;
    margin-left: 0 !important;
  }
  
  .user-cell {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .user-avatar {
    margin-right: 0;
    margin-bottom: 8px;
  }
  
  .status-badge {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  
  .users-table .ant-table-thead > tr > th, 
  .users-table .ant-table-tbody > tr > td {
    padding: 10px;
  }
  
  .action-buttons {
    opacity: 1;
    margin-top: 8px;
  }
  
  .permission-switches {
    max-height: none;
    overflow-y: visible;
  }
  
  .ant-col-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .admin-dashboard-content {
    padding: 16px;
    overflow: visible;
  }
  
  /* Ensure table container is properly scrollable on mobile */
  .user-table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  /* Ensure the table allows horizontal scrolling on mobile */
  .ant-table-content {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  /* Give priority to z-index for proper layering */
  .ant-drawer {
    z-index: 1050 !important;
  }
}

/* Further enhance tab styling */
.user-tabs .ant-tabs-tab {
  transition: all 0.3s;
  border: none;
  background-color: #f8fafc;
  border-radius: 10px;
  margin: 0 6px;
  overflow: hidden;
}

.user-tabs .ant-tabs-tab:hover {
  background-color: #e0f2fe;
  transform: translateY(-2px);
}

.user-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background: linear-gradient(135deg, #3b82f6, #1e40af);
  color: white;
  transform: translateY(-3px);
  box-shadow: 0 6px 16px rgba(59, 130, 246, 0.3);
}

.user-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}

.user-tabs .ant-tabs-ink-bar {
  display: none;
}

/* Make the inactive badge lighter */
.ant-badge.tab-badge .ant-badge-count {
  font-weight: 500;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  padding: 0 6px;
  border-radius: 10px;
  min-width: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Make sure table scrolls horizontally on mobile */
.users-table {
  overflow-x: auto;
}

.ant-table-wrapper {
  overflow-x: auto;
}

/* Fix drawer scrolling issues */
.user-drawer .ant-drawer-body {
  overflow-y: auto !important;
  max-height: calc(100vh - 150px) !important;
}

/* Add these styles to fix scrolling issues */

/* Fix main layout scrolling */
html, body {
  height: 100%;
  overflow: auto;
}

/* Ensure the layout fills the entire screen and allows scrolling */
.admin-dashboard-layout {
  min-height: 100vh;
  background: linear-gradient(135deg, #f6f8fc, #eef2f9);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.admin-dashboard-content {
  padding: 32px;
  margin: 0 auto;
  max-width: 1280px;
  animation: fadeIn 0.5s ease-in-out;
  flex: 1;
  width: 100%;
  overflow: visible;
}

/* Make the container scrollable */
.scrollable-container {
  overflow: auto;
  max-height: calc(100vh - 250px);
}

/* Add proper heights to ensure the content is scrollable */
.user-table-container {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(31, 38, 135, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 100%;
}

/* Fix table scrolling */
.users-table {
  overflow: auto;
  width: 100%;
}

.ant-table-wrapper {
  overflow: auto;
  width: 100%;
}

/* Make sure the drawer scrolls properly */
.user-drawer .ant-drawer-body {
  overflow-y: auto !important;
  max-height: calc(100vh - 150px) !important;
}

/* Fix scrolling in expanded permission sections */
.expanded-row-content {
  overflow-y: auto;
  max-height: 300px;
}

/* Fix permissions section in the drawer */
.permission-switches {
  overflow-y: auto;
  max-height: 300px;
}

/* Adjust mobile styles */
@media (max-width: 768px) {
  .admin-dashboard-content {
    padding: 16px;
  }
  
  /* Ensure table container is properly scrollable on mobile */
  .user-table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  /* Ensure the table allows horizontal scrolling on mobile */
  .ant-table-content {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  /* Give priority to z-index for proper layering */
  .ant-drawer {
    z-index: 1050 !important;
  }
}

/* Force the layout to allow scrolling */
#root {
  height: auto;
  min-height: 100vh;
  overflow: auto;
}

/* Add the mobile scroll indicator */
.mobile-scroll-indicator {
  display: none;
  text-align: center;
  color: #64748b;
  font-size: 12px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  margin: 8px 0;
}

@media (max-width: 768px) {
  .mobile-scroll-indicator {
    display: block;
  }
}

/* Add visible scroll indicators on mobile */
.user-table-container:after {
  content: 'Scroll to see more →';
  display: block;
  text-align: center;
  padding: 8px;
  color: #8c8c8c;
  font-size: 12px;
}

/* Debug styles to force visibility */
[class*="ant-btn"] {
  opacity: 1 !important;
  visibility: visible !important;
  display: inline-flex !important;
  z-index: 999 !important;
}

.ant-table-cell:last-child {
  background: rgba(240, 240, 240, 0.2);
  z-index: 10 !important;
}

/* Debug styles to force action buttons visibility */
.action-buttons {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  opacity: 1 !important;
  visibility: visible !important;
  min-width: 90px !important;
  position: relative !important;
  z-index: 999 !important;
}

/* Force button visibility */
.edit-button, .delete-button {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  visibility: visible !important;
  opacity: 1 !important;
  z-index: 1000 !important;
  margin: 0 4px !important;
  position: relative !important;
  cursor: pointer !important;
}

/* Prevent hiding buttons */
.action-column {
  background-color: white !important;
  position: sticky !important;
  right: 0 !important;
  z-index: 10 !important;
  box-shadow: -3px 0 5px rgba(0,0,0,0.05) !important;
}

/* Make absolutely sure no parent element hides the buttons */
.ant-table-tbody > tr > td:last-child {
  position: relative !important;
  overflow: visible !important;
}