/* Base container */
.login-container {
  position: fixed;
  inset: 0;
  background: linear-gradient(135deg, #1e293b 0%, #0f172a 100%);
  min-height: 100vh;
  display: flex;
}

/* Split layout */
.min-h-screen.w-full.flex {
  width: 100%;
  display: flex;
}

/* Left side content */
.hidden.lg\:flex.lg\:w-1\/2 {
  width: 50%;
  padding: 2rem;
  display: none;
  position: relative;
  overflow-y: auto;
}

/* Left side content container */
.relative.w-full.max-w-2xl {
  position: relative;
  width: 100%;
  max-width: 42rem;
  margin: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}

/* Feature cards section */
.grid.gap-6.mb-8 {
  display: grid;
  gap: 1.5rem;
  width: 100%;
}

/* Feature card */
.group.bg-white\/80 {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0.75rem;
  padding: 1.5rem;
  width: 100%;
}

/* Right side container */
.w-full.lg\:w-1\/2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

/* Login form wrapper */
.content-wrapper {
  width: 100%;
  max-width: 28rem;
  margin: auto;
}

.login-highlight {
  width: 100%;
}

/* Responsive adjustments */
@media (min-width: 1024px) {
  .hidden.lg\:flex.lg\:w-1\/2 {
    display: flex;
  }

  .w-full.lg\:w-1\/2 {
    width: 50%;
  }
}

@media (max-width: 1024px) {
  .relative.w-full.max-w-2xl {
    padding: 1.5rem;
  }

  .w-full.lg\:w-1\/2 {
    width: 100%;
  }

  .content-wrapper {
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  .w-full.lg\:w-1\/2 {
    padding: 1rem;
  }

  .relative.w-full.max-w-2xl {
    padding: 1rem;
  }

  .grid.gap-6.mb-8 {
    gap: 1rem;
  }

  .group.bg-white\/80 {
    padding: 1rem;
  }

  .text-4xl {
    font-size: 1.875rem;
  }
}

@media (max-width: 480px) {
  .w-full.lg\:w-1\/2 {
    padding: 0.75rem;
  }

  .content-wrapper {
    padding: 0.75rem;
  }

  .text-4xl {
    font-size: 1.5rem;
  }
}

/* Fix height and scrolling */
.bg-\[\#0f172a\].relative.overflow-hidden {
  min-height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Center alignment fixes */
.flex.items-center {
  display: flex;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

/* Text alignment */
.text-center {
  text-align: center;
}

/* Remove animations */
* {
  animation: none !important;
  transition: none !important;
  transform: none !important;
}

/* Performance optimizations */
.login-container,
.content-wrapper,
.login-highlight {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
}

/* Fix content overflow */
.overflow-hidden {
  overflow: visible;
}

/* Ensure proper spacing */
.space-y-6 > * + * {
  margin-top: 1.5rem;
}

/* Fix mobile padding */
@media (max-width: 640px) {
  .p-8 {
    padding: 1.25rem;
  }
  
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
